export default defineNuxtRouteMiddleware((to) => {
  const nuxt = useNuxtApp()
  const auth = useState('auth')
  const guardLayouts = ['account', 'yousign']

  const redirectLink = nuxt.$localePath({
    name: 'login',
    query: { return_to: to.fullPath },
  })

  // Protected layout from auth is authenticated
  const protectedRouteFromAuth = computed(() => {
    return !auth?.value?.authenticated && guardLayouts.includes(to.meta.layout)
  })

  if (protectedRouteFromAuth.value) {
    return navigateTo(redirectLink)
  }
})
