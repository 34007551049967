import { useGtm } from '@gtm-support/vue-gtm'
import { hashDataAndSetForGtm } from '~/utils/hashDataAndSetForGtm'

export const useTracking = () => {
  const gtm = useGtm()

  // TRACKEVENT
  const trackEvent = async ({
    category,
    event,
    label,
    nonInteraction = false,
    hash,
    ...rest
  }: {
    category?: string
    event: string
    label?: string | Record<string, string>
    nonInteraction?: boolean
    hash?: {
      email?: string
      phone?: string
    }
    [x: string]: unknown
  }) => {
    if (hash && Object.values(hash).length > 0) {
      await hashDataAndSetForGtm(hash)
    }

    let labelProperties = label || ''

    if (typeof labelProperties === 'object') {
      const keys = Object.keys(labelProperties)
      const result = keys.map((key) => {
        return `${key}:${labelProperties[key as keyof typeof labelProperties]}`
      })

      labelProperties = result.join(', ') as unknown as Record<string, string>
    }

    // GTM
    if (gtm) {
      gtm.push({
        category,
        event,
        label: labelProperties,
        nonInteraction,
        ...rest,
      })
    }
  }

  return {
    trackEvent,
  }
}
