<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(
  defineProps<{
    disabled?: boolean
    hasBorder?: boolean
    infoText?: string
    inputHtml?: string
    label?: string
    labelHtml?: string
    max?: number
    min?: number
    modelValue: number
    name: string
    placeholder?: string
    rules?: string
    wrapperClass?: string
  }>(),
  {
    disabled: false,
    hasBorder: true,
    infoText: '',
    inputHtml: '',
    label: '',
    labelHtml: '',
    max: Infinity,
    min: 0,
    placeholder: '',
    rules: '',
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  blur: []
  'update:model-value': [number]
}>()
const rulesInput = ref(props.rules)
const {
  value: inputValue,
  handleBlur,
  handleChange,
  errors,
} = useField<number>(props.name, rulesInput, {
  initialValue: props.modelValue,
})

const increment = (operator: string) => {
  let value = Number(inputValue.value)

  if (operator === '+' && value < props.max) {
    value++
  } else if (operator === '-' && value > props.min) {
    value--
  }
  handleChange(value)
  emits('update:model-value', value)
}

const inputChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  handleChange(target.value)

  emits('update:model-value', Number(target.value))
}
const isError = computed(() => Boolean(errors.value.length))

const onBlur = (): void => {
  handleBlur()
  emits('blur')
}

const computedClass: ComputedRef<string[]> = computed(() => {
  const computeClass = ['base-input-number']
  if (!props.hasBorder) {
    computeClass.push('base-input--noBorder')
  }
  if (props.disabled) {
    computeClass.push('base-input--disabled')
  }
  if (isError.value && props.hasBorder) {
    computeClass.push('base-input--hasError-hasBorder')
  }
  if (!isError.value && props.hasBorder) {
    computeClass.push('base-input--hasntError-hasBorder')
  }

  return computeClass
})

watch(
  () => props.rules,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      rulesInput.value = newValue
    }
  },
)

defineExpose({
  inputValue,
})
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex mb-2">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <div class="flex flex-wrap items-center">
      <button
        data-testid="inputNumber-prev-button"
        type="button"
        class="w-6 h-6 p-1 flex items-center justify-center rounded-full border border-gray-200 duration-300 hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-300 text-gray-700 focus:outline-none"
        :disabled="inputValue <= min"
        @click="increment('-')"
      >
        <BaseIcon name="minus" :size="1" />
      </button>

      <input
        :id="name"
        :ref="name"
        data-testid="inputNumber"
        :class="computedClass"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :value="inputValue"
        v-bind="$attrs"
        @blur="onBlur"
        @change="inputChange"
      />

      <button
        data-testid="inputNumber-next-button"
        type="button"
        :disabled="inputValue >= max"
        class="w-6 h-6 p-1 flex items-center justify-center rounded-full border border-gray-700 duration-300 hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-300 text-gray-700 focus:outline-none"
        @click="increment('+')"
      >
        <BaseIcon name="plus" :size="1" />
      </button>

      <BaseFormComponentsBaseStringToHtml
        v-if="inputHtml"
        :str-html="inputHtml"
      />
    </div>

    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="infoText" />

    <error-message :name="name" as="span" class="base-form--error" />
  </div>
</template>

<style>
.base-input-number {
  height: 48px;
  width: 58px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @apply block bg-clip-padding text-center text-gray-500 font-normal text-base leading-normal py-1.5 bg-white focus:text-gray-500 focus:bg-white focus:border-primary-100 focus:shadow-focus focus:outline-none border rounded-sm mx-2;
}
.base-input-number--noBorder {
  @apply border-none;
}
.base-input-number--disabled {
  @apply bg-gray-200 text-gray-500 cursor-not-allowed;
}
.base-input-number--hasError-hasBorder {
  @apply border-error;
}
.base-input-number--hasntError-hasBorder {
  @apply border-gray-200;
}
.base-input-number[disabled='disabled'] {
  -webkit-text-fill-color: #aaaaaa;
}
</style>
