<script setup lang="ts">
const route = useRoute()
const config = useRuntimeConfig()

const emits = defineEmits<{
  tracking: [label: string]
}>()

const props = withDefaults(
  defineProps<{
    appendActions?: string[]
    urlCustom?: string
  }>(),
  {
    appendActions: () => ['copyLink', 'email', 'whatsapp'],
    urlCustom: '',
  },
)

const urlCopied = ref(false)

const url = computed(
  () => props.urlCustom || `${config.public.baseUrl}${route.fullPath}`,
)

const actions = computed(() => {
  return [
    {
      click: copyLink,
      icon: 'copy' as const,
      key: 'copyLink',
      keyTrad: 'house.share.copyLink',
      labelTracking: 'copy_link',
    },
    {
      href: `mailto:?body=${url.value}`,
      icon: 'mail' as const,
      key: 'email',
      keyTrad: 'house.share.sendByEmail',
      labelTracking: 'email',
    },
    {
      href: `whatsapp://send?text=${url.value}`,
      icon: 'whatsappLc' as const,
      key: 'whatsapp',
      keyTrad: 'house.share.whatsapp',
      labelTracking: 'whatsapp',
    },
  ].filter((action) => props.appendActions.includes(action.key))
})
const copyLink = (label: string) => {
  urlCopied.value = true
  navigator.clipboard.writeText(url.value)
  tracking(label)
}
const tracking = (label: string) => {
  emits('tracking', label)
}
</script>

<template>
  <div class="border border-gray-200 rounded">
    <div
      v-for="(action, i) in actions"
      :key="i"
      class="border-b border-gray-200 last:border-b-0"
    >
      <BaseButton
        v-if="action.click"
        class="!bg-transparent hover:text-primary-700 p-4 flex items-center justify-between w-full"
        color="light"
        font-weight="font-normal"
        has-icon
        :aria-label="$t(action.keyTrad)"
        @click="action.click(action.labelTracking)"
      >
        <span v-if="urlCopied" class="flex items-center">
          <span class="text-md">{{ $t('house.share.linkCopied') }}</span>
          <BaseIcon name="check" />
        </span>
        <span v-else class="text-md">{{ $t(action.keyTrad) }}</span>
        <BaseIcon :name="action.icon" />
      </BaseButton>
      <BaseButton
        v-else
        class="!bg-transparent hover:text-primary-700 p-4 flex items-center justify-between w-full"
        color="light"
        font-weight="font-normal"
        has-icon
        :aria-label="$t(action.keyTrad)"
        :href="action.href"
        @click="tracking(action.labelTracking)"
      >
        <span class="text-md">{{ $t(action.keyTrad) }}</span>
        <BaseIcon class="!text-xl" :name="action.icon" />
      </BaseButton>
    </div>
  </div>
</template>
