export const moduleContractForm = {
  namespaced: true,
  state() {
    return {
      contractFailed: false,
    }
  },
  mutations: {
    SET_CONTRACT_FAILED(state, payload) {
      state.contractFailed = payload
    },
  },
  actions: {
    setContractFailed({ commit }, value) {
      commit('SET_CONTRACT_FAILED', value)
    },
  },
}
