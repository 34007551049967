<script setup lang="ts">
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
  }>(),
  {
    modelValue: false,
  },
)

const close = () => {
  emits('update:modelValue', !props.modelValue)
}
</script>

<template>
  <div
    :class="[
      'base-dropup fixed top-0 left-0 w-full h-full',
      { visible: modelValue },
      { invisible: !modelValue },
    ]"
    data-testid="base-dropup"
  >
    <div
      :class="['base-dropup-bg', { open: modelValue }]"
      data-testid="base-dropup-bg"
      @click="close"
    />
    <transition name="dropup">
      <div
        v-if="modelValue"
        :class="[
          'base-dropup-container overflow-y-scroll bg-white absolute bottom-0 left-0 h-full w-full',
        ]"
        data-testid="base-dropup-container"
      >
        <div class="py-3 px-4 flex justify-end">
          <base-close-button
            class="focus:outline-none"
            data-testid="base-dropup-close"
            @click="close"
          />
        </div>
        <slot />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.dropup-enter-active,
.dropup-leave-active {
  transition: all 0.5s ease-in-out;
}

.dropup-enter,
.dropup-leave-to {
  transform: translate3d(0, 100%, 0);
}

/* overlay */
.base-dropup-bg {
  background: rgba(0, 0, 0, 0.5);
  transition:
    visibility 0.7s linear,
    opacity 0.5s ease-in-out;
  @apply fixed top-0 left-0 w-full h-full opacity-0 invisible;
}

.base-dropup-bg.open {
  @apply opacity-100 visible;
}
</style>
