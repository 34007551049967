import { createStore } from 'vuex'

import { moduleAuth } from '~/store/auth'
import { moduleContractForm } from '~/store/contractForm'
import { modulePreOrder } from '~/store/preOrder'

export default defineNuxtPlugin((nuxtApp) => {
  const localePath = useLocalePath()
  const router = useRouter()
  const { $api, $httpCookies, $lcRepositories } = nuxtApp

  const store = createStore({
    modules: {
      auth: moduleAuth({
        $api,
        $cookies: $httpCookies,
        $lcRepositories,
        localePath,
        router,
      }),
      contractForm: moduleContractForm,
      preOrder: modulePreOrder({
        $httpCookies,
      }),
    },
  })

  // Install the store instance as a plugin
  nuxtApp.vueApp.use(store)

  return {
    provide: {
      storeGlobal: { ...store },
    },
  }
})
