export const useAlgoliaSearchKey = (): Ref<string> => {
  type AuthVM = {
    user: {
      isAdmin: boolean
      partnershipAgency: boolean
      algoliaAdminSearchKey: string
      algoliaB2b2cSearchKey: string
    }
  }

  const config = useRuntimeConfig()
  const { userIsAdmin, userIsPartner } = useAuthType()

  const auth: {
    value: AuthVM | null
  } = useState('auth')

  // Search Key
  let algoliaSearchKey = config.public.algoliaSearchKey
  if (userIsAdmin.value) {
    algoliaSearchKey =
      auth?.value?.user?.algoliaAdminSearchKey || config.public.algoliaSearchKey
  }
  if (userIsPartner.value) {
    algoliaSearchKey =
      auth?.value?.user?.algoliaB2b2cSearchKey || config.public.algoliaSearchKey
  }

  return ref(algoliaSearchKey)
}
