<script setup lang="ts">
import { getDestinationSlug } from '~/helpers/getDestinationSlug'

import TheHeaderDropdownTemplate from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdownTemplate.vue'
import type { HouseSlug, Locale } from 'lc-services/types'
import type { RouteLocation } from 'vue-router'
import type { InspiLink } from '~/composables/Header/useHeaderNavigation'

const { locale } = useI18n()
const Route = useRoute()
const { headerNavigation, setNextRoute } = useHeaderNavigation()

const activeLink = ref<InspiLink>({
  id: null,
  img: '',
  href: {
    fr: '',
    en: '',
  },
  text: '',
  titleEn: '',
  titleFr: '',
})

const inspirationsLinks = computed(
  () => headerNavigation.value.inspirationsLinks,
)

onMounted(() => {
  if (inspirationsLinks.value.length > 0) {
    setActiveLink(inspirationsLinks.value[0])

    // need to set active link for pages contained in the nav
    const formattedRoute = String(Route.params.name)

    if (
      inspirationsLinks.value.some(
        (link) => link.href[locale.value as Locale] === formattedRoute,
      )
    ) {
      activeLink.value.href[locale.value as Locale] = formattedRoute
    }
  }
})

const onLinkClicked = (route: RouteLocation) => {
  // trigger async navigation
  setNextRoute(route)
}
const setActiveLink = (link: InspiLink) => {
  activeLink.value = link
}
const getPathDestination = (destinationNames: HouseSlug) =>
  getDestinationSlug(destinationNames)[locale.value as Locale].path
const imageAlt = (link?: { titleFr: string; titleEn: string }) => {
  const tranlateText = locale.value === 'fr' ? link?.titleFr : link?.titleEn

  return tranlateText || ''
}
</script>

<template>
  <the-header-dropdown-template>
    <template #content-left>
      <div
        v-if="inspirationsLinks.length"
        class="flex items-center h-full mt-6"
      >
        <div class="w-[300px] h-[300px] relative">
          <template v-for="link in inspirationsLinks" :key="link.img">
            <div
              class="rounded-md mx-auto wrap-image-with-border absolute top-0 left-0"
            >
              <BaseNuxtImg
                :alt="imageAlt(link)"
                :src="link.img"
                height="300"
                loading="lazy"
                width="300"
                :class="[
                  'duration-300',
                  {
                    'opacity-100': link.id === activeLink.id,
                    'opacity-0': link.id !== activeLink.id,
                  },
                ]"
              />
            </div>
          </template>
        </div>
        <nav class="w-3/5 pl-12">
          <p class="text-6xl font-bold text-gray-700 font-monospace">
            {{ $t('headerInspirations.exploreCollections') }}
          </p>
          <ul v-if="inspirationsLinks" aria-labelledby="inspirations">
            <li
              v-for="(link, index) in inspirationsLinks"
              :key="index"
              class="relative h-6 mt-4 max-w-max"
            >
              <nuxt-link
                v-slot="{ href, isActive, route }"
                :to="getPathDestination(link.href)"
                custom
              >
                <a
                  data-testid="dropdown-inspirations__links"
                  :class="[
                    'dropdown-link',
                    { 'dropdown-link--active': isActive },
                  ]"
                  :href="href"
                  @mouseover="setActiveLink(link)"
                  @click.prevent="onLinkClicked(route)"
                >
                  {{ $i18n.locale === 'fr' ? link.titleFr : link.titleEn }}
                </a>
              </nuxt-link>
            </li>
          </ul>
        </nav>
      </div>
    </template>
    <template #content-right>
      <div
        class="flex flex-col items-center justify-center h-full mt-12 ml-16 text-white"
      >
        <p class="text-2xl font-bold font-monospace">
          {{ $t('headerInspirations.moreInspirations') }}
        </p>
        <p class="w-1/2 mx-auto mt-4 text-center">
          {{ $t('headerInspirations.joinMagazine') }}
        </p>

        <base-button
          class="mt-8"
          color="secondary"
          target="_blank"
          :href="$t('global.blog_link')"
        >
          {{ $t('headerInspirations.exploreTheMagazine') }}
        </base-button>
      </div>
    </template>
  </the-header-dropdown-template>
</template>
