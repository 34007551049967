<script setup lang="ts">
const emits = defineEmits<{
  'inquiry-finished': []
}>()

const { $lcServicesWishlist } = useNuxtApp()
const { wishlistController, wishlistPresenter } = $lcServicesWishlist
const { wishlistActive } = useMultipleWishlistSummary()

const mutation = async ({
  clientComment,
  numberOfAdults,
  numberOfChildren,
  numberOfBabies,
}: {
  clientComment: string
  numberOfAdults: number
  numberOfChildren: number
  numberOfBabies: number
}) => {
  if (wishlistActive.value?.privateToken) {
    await wishlistController.modifyRequestWishlist({
      client_comment: clientComment,
      number_of_adults: numberOfAdults,
      number_of_babies: numberOfBabies,
      number_of_children: numberOfChildren,
      private_token: wishlistActive.value.privateToken,
    })

    if (wishlistPresenter.vm.modifyRequestWishlistSucceeded) {
      emits('inquiry-finished')
    }
  }
}
</script>

<template>
  <InquiryAdditionalInformation
    :mutation="mutation"
    button-translation="global.send"
  >
    <template #header>
      <div class="flex mb-6">
        <h5 class="text-xl mb-0 mr-2">
          {{ $t('wishlistMultiple.summary.sharedList') }}
        </h5>
        <BaseIcon class="flex-none text-secondary-400" name="checkCircle" />
      </div>

      <p class="text-xs mb-2">
        {{ $t('wishlistMultiple.summary.additionalInformationText[0]') }}
        {{ $t('wishlistMultiple.summary.additionalInformationText[1]') }}
      </p>
    </template>
  </InquiryAdditionalInformation>
</template>
