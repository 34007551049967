import type Cookies from 'universal-cookie/es6/Cookies'
import { setNewClientApi } from '~/repository/config/index'
import { getApiURL } from '~/repository/config/api-url'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { $i18n, $httpCookies } = nuxtApp
  const baseURL = getApiURL({ locale: ($i18n as any).locale.value, config })
  const showSsrLog = config.public.ssrLog === 'true'

  const { clientApi, setHeader } = setNewClientApi({
    baseURL,
    cookies: $httpCookies as Cookies,
    fetcher: useCustomFetch,
    showSsrLog,
  })

  return {
    provide: {
      api: { ...clientApi, setHeader },
    },
  }
})
