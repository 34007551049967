<script setup lang="ts">
withDefaults(
  defineProps<{
    tooltipPosition?: 'bottom-right'
  }>(),
  {
    tooltipPosition: 'bottom-right',
  },
)

const localePath = useLocalePath()
const { locale } = useI18n()
const config = useRuntimeConfig()
const openTime = ref('')

const { data } = await useFetch<{
  openingTime: Record<string, string>
}>(`/api/opening-time`)
openTime.value = data.value?.openingTime
  ? data.value.openingTime[locale.value]
  : ''

const dayUTC = new Date().getUTCDay()
const parisTime = new Date().toLocaleTimeString('fr-FR', {
  timeZone: 'Europe/Paris',
})
const [hour, minute] = parisTime.split(':')

const callsUntilMidnight = computed(
  () => config.public.callsUntilMidnight === 'true',
)

const paris = new Date()
paris.setUTCHours(Number(hour))
paris.setUTCMinutes(Number(minute))

const openWeekDay = new Date()
openWeekDay.setUTCHours(9)
openWeekDay.setUTCMinutes(30)

const closeWeekDay = new Date()
closeWeekDay.setUTCHours(callsUntilMidnight.value ? 23 : 18)
closeWeekDay.setUTCMinutes(callsUntilMidnight.value ? 59 : 30)

const openWeekendDay = new Date()
openWeekendDay.setUTCHours(9)
openWeekendDay.setUTCMinutes(30)

const closeWeekendDay = new Date()
closeWeekendDay.setUTCHours(18)
closeWeekendDay.setUTCMinutes(30)

const currentlyOpenWeekDay = computed(() => {
  return (
    ![0, 6].includes(dayUTC) &&
    paris.getTime() >= openWeekDay.getTime() &&
    paris.getTime() <= closeWeekDay.getTime()
  )
})

const currentlyOpenWeekendDay = computed(() => {
  return (
    [6].includes(dayUTC) &&
    paris.getTime() >= openWeekendDay.getTime() &&
    paris.getTime() <= closeWeekendDay.getTime()
  )
})

const isOpen = computed(() => {
  return currentlyOpenWeekDay.value || currentlyOpenWeekendDay.value
})
</script>

<template>
  <base-tooltip
    :position="tooltipPosition"
    class="header-phone"
    show
    variant="white"
  >
    <template #element>
      <slot />
    </template>

    <template #text>
      <div class="flex text-left items-center mb-4">
        <div class="relative">
          <BaseIcon class="mr-4" name="phone" />
          <BaseIcon
            :class="[
              'mr-4 absolute bottom-[-10px] right-[2px]',
              {
                'stroke-white fill-secondary-500': isOpen,
                'stroke-white fill-error': !isOpen,
              },
            ]"
            :name="isOpen ? 'checkCircle' : 'deleteCircle'"
            :size="1"
          />
        </div>

        <div
          class="the-header-phone-schedule text-md m-0 text-gray-600"
          data-testid="the-header-phone-schedule"
        >
          <div v-html="openTime" />
          <span class="block uppercase font-bold">
            <template v-if="isOpen">
              <span class="text-secondary-500">{{
                $t('header.currentlyOpen')
              }}</span>
            </template>
            <template v-else>
              {{ $t('header.currentlyClosed') }}
            </template>
          </span>
        </div>
      </div>

      <div class="flex text-left items-center">
        <BaseIcon class="mr-4" name="mail" />
        <i18n-t
          keypath="header.contactHelp"
          tag="span"
          class="text-md text-gray-600"
          data-testid="the-header-phone-contact"
        >
          <template #link>
            <nuxt-link
              :to="localePath('contact')"
              class="font-bold text-primary-700 hover:text-primary-600"
            >
              {{ $t('header.form') }}
            </nuxt-link>
          </template>
        </i18n-t>
      </div>
    </template>
  </base-tooltip>
</template>

<style>
.header-phone.lc_tooltip .lc_tooltip-content {
  max-width: 34rem;
}

@media (min-width: 1280px) {
  .header-phone.lc_tooltip .lc_tooltip-content {
    min-width: 31rem;
  }
}

/* need to keep the tooltip open when hovering */
.header-phone:after {
  content: '';
  @apply absolute -bottom-3 left-0 w-full pb-3;
}
.the-header-phone-schedule p {
  margin: 0;
}
</style>
