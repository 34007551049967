<script setup lang="ts">
import TheHeaderDropdownTemplate from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdownTemplate.vue'

import { aboutLinks } from '~/assets/js/aboutLinks'
import { formatRoute } from '~/helpers/formatRoute.js'
import type { RouteLocation } from 'vue-router'

const Route = useRoute()
const localePath = useLocalePath()
const { setNextRoute } = useHeaderNavigation()

const activeLink = ref('customHolidays')
const links = ref(aboutLinks)

const onLinkClicked = (route: RouteLocation) => {
  // trigger async navigation
  setNextRoute(route)
}

const setActiveLink = (link: string) => {
  activeLink.value = link
}

onMounted(() => {
  // need to set active link for pages contained in the nav
  const formattedRoute = formatRoute(String(Route.name ?? ''))

  if (links.value.some((link) => link.href === formattedRoute)) {
    activeLink.value = formattedRoute
  }
})
</script>

<template>
  <the-header-dropdown-template>
    <template #content-left>
      <div class="flex items-center h-full mt-6">
        <div class="w-[300px] h-[300px] relative">
          <template v-for="link in links" :key="link.img">
            <div
              class="rounded-md mx-auto wrap-image-with-border absolute top-0 left-0"
            >
              <BaseNuxtImg
                :key="link.img"
                :alt="$t(link.text)"
                :src="link.img"
                height="300"
                loading="lazy"
                width="300"
                :class="[
                  'duration-300',
                  {
                    'opacity-100': link.href === activeLink,
                    'opacity-0': link.href !== activeLink,
                  },
                ]"
              />
            </div>
          </template>
        </div>

        <nav class="w-3/5 pl-12">
          <p
            id="universe"
            class="text-6xl font-bold text-gray-700 font-monospace"
          >
            {{ $t('headerAbout.universe') }}
          </p>

          <ul aria-labelledby="universe">
            <li
              v-for="link in links"
              :key="link.href"
              class="relative h-6 mt-4 max-w-max"
            >
              <nuxt-link
                v-slot="{ href, isActive, route }"
                :to="localePath(link.href)"
                custom
              >
                <a
                  data-testid="dropdown-about__links"
                  :class="[
                    'dropdown-link',
                    { 'dropdown-link--active': isActive },
                  ]"
                  :href="href"
                  @mouseover="setActiveLink(link.href)"
                  @click.prevent="onLinkClicked(route)"
                >
                  {{ $t(link.text) }}
                </a>
              </nuxt-link>
            </li>
          </ul>
        </nav>
      </div>
    </template>
    <template #content-right>
      <div
        class="flex flex-col items-center justify-center h-full mt-12 ml-16 text-white"
      >
        <p class="text-2xl font-bold font-monospace">
          {{ $t('headerAbout.submitProperty') }}
        </p>
        <p class="w-1/2 mx-auto mt-4 text-center">
          {{ $t('headerAbout.joinCollection') }}
        </p>
        <nuxt-link v-slot="{ href, route }" :to="localePath('owners')" custom>
          <a
            class="mt-8 lc-btn--secondary lc-btn--md lc-btn lc-btn--font-bold"
            :href="href"
            @click.prevent="onLinkClicked(route)"
          >
            {{ $t('headerAbout.cta') }}
          </a>
        </nuxt-link>
      </div>
    </template>
  </the-header-dropdown-template>
</template>

<style>
.dropdown-link {
  user-select: none;
  transition: color 0.2s ease-in-out;
  @apply text-gray-700 bg-transparent p-0 inline-block outline-none focus:outline-none hover:text-secondary-500 hover:font-semibold hover:no-underline;
}

.dropdown-link--active {
  @apply text-secondary-500 font-bold;
}

.dropdown-link:after {
  content: '';
  bottom: -4px;
  transform: scale(0);
  @apply absolute left-0 right-0 w-full h-px mx-0 my-auto bg-secondary-500 origin-left transition-transform ease-in-out duration-500;
}

.dropdown-link:not(.dropdown-link--active):hover:after {
  transform: scale(1);
}
</style>
