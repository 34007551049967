<template>
  <div class="relative flex rounded border border-gray-200">
    <div class="w-[88px] h-[88px] rounded-l animate-wave"></div>
    <div
      class="grow px-4 py-2 flex flex-col justify-center w-[calc(100%-88px)]"
    >
      <div class="w-10/12 h-4 animate-wave mb-2"></div>
      <div class="w-full h-3 animate-wave mb-4"></div>
      <div class="w-full h-3 animate-wave"></div>
      <div class="absolute top-2 right-2">
        <BaseIcon
          name="heart"
          class="fill-gray-200 stroke-gray-200"
          :stroke-width="1"
          :size="1"
        />
      </div>
    </div>
  </div>
</template>
