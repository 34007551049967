import type Cookies from 'universal-cookie/es6/Cookies'
import { formatResponseApi as serializeData } from './api-serializer'
import type { FetchError, FetchResponse } from 'ofetch'

const setApiInterceptors = <T = unknown>(
  res: FetchResponse<T>,
  url: string,
) => {
  const date = new Date()
  const dateTime = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
  const grey = '\x1B[90m'
  const green = '\x1B[32m'

  // format 27.1.2021 19:7:49 200 api/v1/..
  const message = (res: FetchResponse<T>, path: string) => {
    const date = `${grey} ${dateTime}`
    const status = `${green} ${(res.body as unknown as Record<string, string>)?.status || res.status}`
    const url = `${grey} ${path}`

    return `${date} ${status} ${url}`
  }

  return message(res, url)
}

export class HttpService {
  $fetch: typeof useCustomFetch
  baseURL: string
  hasHeader?: boolean
  hasHttpSource?: boolean
  showSsrLog?: boolean
  headers: HeadersInit
  cookies: Cookies

  constructor({
    fetcher,
    baseURL,
    hasHeader = true,
    hasHttpSource = true,
    showSsrLog = false,
    cookies,
  }: {
    fetcher: typeof useCustomFetch
    baseURL: string
    hasHeader?: boolean
    hasHttpSource?: boolean
    showSsrLog?: boolean
    cookies: Cookies
  }) {
    this.$fetch = fetcher
    this.baseURL = baseURL
    this.showSsrLog = showSsrLog
    this.hasHeader = hasHeader
    this.hasHttpSource = hasHttpSource
    this.headers = this.hasHttpSource ? { httpSource: 'web' } : {}
    this.cookies = cookies
  }

  setHeader = (headers: HeadersInit) => {
    return new Promise<void>((resolve) => {
      if (Object.values(headers).every(Boolean)) {
        if (this.hasHttpSource) {
          this.headers = { httpSource: 'web', ...headers }
        } else {
          this.headers = {
            ...headers,
          }
        }
      }

      resolve()
    })
  }

  getHeaders = (headers: Headers) => {
    const accessToken = headers?.get('access-token') || ''
    const client = headers?.get('client') || ''
    const uid = headers?.get('uid') || ''

    return {
      accessToken,
      client,
      uid,
    }
  }

  formatErrorApi = (err: FetchError) => {
    const responseErrors = {
      response: {
        status: err?.response?.status || 500,
      },
    } as FetchError & { response: FetchError['response'] & { data: any } }

    if (err?.response?._data?.errors) {
      responseErrors.response.data = {
        ...err.response._data,
      }
    }

    // Ne rentre jamais dedans je pense --"
    return Promise.reject(responseErrors)
  }

  setHeadersOnResponse = async (res: FetchResponse<unknown>) => {
    const { accessToken, client, uid } = this.getHeaders(res.headers)

    if (accessToken && client && uid) {
      this.cookies.set('uid', uid)
      this.cookies.set('client', client)
      this.cookies.set('access-token', accessToken)

      await this.setHeader({
        'access-token': accessToken,
        client,
        uid,
      })
    }

    return { accessToken, client, uid }
  }

  triggerOnResponse = async <T = unknown>(
    response: FetchResponse<T>,
    url: string,
  ) => {
    await this.setHeadersOnResponse(response)

    if (this.showSsrLog) {
      setApiInterceptors(response, url)
    }
  }

  handleOnRequest = async () => {
    if (
      this.cookies?.get('access-token') &&
      this.cookies?.get('client') &&
      this.cookies?.get('uid')
    ) {
      return await this.setHeader({
        'access-token': this.cookies.get('access-token'),
        client: this.cookies.get('client'),
        uid: this.cookies.get('uid'),
      })
    }
  }

  post = async <T extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    data: T,
  ) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'POST',
        options: {
          body: { ...data },
          headers: { ...this.headers },
          onRequest: () => {
            return this.handleOnRequest()
          },
          onResponse: ({ response }) => {
            return this.triggerOnResponse(response, url)
          },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }

  get = async <T extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    data: T,
  ) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'GET',
        options: {
          params:
            data && 'params' in data && typeof data.params === 'object'
              ? { ...data.params }
              : {},
          headers: { ...this.headers },
          onRequest: () => {
            return this.handleOnRequest()
          },
          onResponse: ({ response }) => {
            return this.triggerOnResponse(response, url)
          },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }

  put = async <T extends Record<string, unknown> = Record<string, unknown>>(
    url: string,
    data: T,
  ) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'PUT',
        options: {
          body: { ...data },
          headers: { ...this.headers },
          onRequest: () => {
            return this.handleOnRequest()
          },
          onResponse: ({ response }) => {
            return this.triggerOnResponse(response, url)
          },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }

  delete = async (url: string) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'DELETE',
        options: {
          headers: { ...this.headers },
          onRequest: () => {
            return this.handleOnRequest()
          },
          onResponse: ({ response }) => {
            return this.triggerOnResponse(response, url)
          },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }

  putFormData = async (url: string, formData: FormData) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'PUT',
        options: {
          body: formData,
          headers: {
            ...this.headers,
            // @todo: check if this header needs a boundary and/or a field name
            'Content-Disposition': 'form-data',
          },
          onRequest: () => {
            return this.handleOnRequest()
          },
          onResponse: ({ response }) => {
            return this.triggerOnResponse(response, url)
          },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }

  upload = async (url: string, headers: HeadersInit = {}) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'GET',
        options: {
          responseType: 'blob',
          headers: this.hasHeader
            ? { ...this.headers, ...headers }
            : { ...headers },
          onRequest: () => {
            return this.handleOnRequest()
          },
          onResponse: ({ response }) => {
            return this.triggerOnResponse(response, url)
          },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }

  getExternalApi = async <
    T extends Record<string, unknown> = Record<string, unknown>,
  >(
    url: string,
    data: T,
  ) => {
    try {
      const response = await this.$fetch({
        baseURL: this.baseURL,
        url,
        method: 'GET',
        options: {
          params:
            data && 'params' in data && typeof data.params === 'object'
              ? { ...data.params }
              : {},
          headers: { ...this.headers },
        },
      })
      return serializeData(response)
    } catch (err) {
      return this.formatErrorApi(err as FetchError)
    }
  }
}
