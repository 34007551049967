<script setup lang="ts">
import type { Locale, SupportedCurrencies } from 'lc-services/types'

const { $httpCookies } = useNuxtApp()
const { getNewUrl } = useSetRouteParams()
const { currency, setCurrency } = useUser()

const currencies = ref<{ symbol?: string; value: SupportedCurrencies }[]>([
  {
    symbol: '€',
    value: 'EUR',
  },
  {
    value: 'CHF',
  },
  {
    symbol: '£',
    value: 'GBP',
  },
  {
    symbol: '$',
    value: 'USD',
  },
])

const currencyActive = computed(() => currency.value)

const changeCurrency = (value: SupportedCurrencies) => {
  setCurrency(value)
  $httpCookies?.set('currency', value)
}
</script>

<template>
  <div class="max-w-xs">
    <div class="font-monospace text-4xl mb-6">
      {{ $t('header.language') }}
    </div>

    <ul class="flex flex-wrap mb-12">
      <li v-for="locale in $i18n.locales" :key="locale.code" class="mr-8">
        <a
          :class="[
            'border uppercase py-3 px-6 flex items-center rounded-3xl outline-none focus:outline-none hover:no-underline',
            {
              'border-gray-700 text-gray-700 hover:text-gray-700 font-medium':
                locale.code === $i18n.locale,
            },
            {
              'border-gray-300 text-gray-400 hover:text-gray-400':
                locale.code !== $i18n.locale,
            },
          ]"
          :href="getNewUrl(locale.code as Locale)"
        >
          <img
            :src="`https://cdn.lecollectionist.com/lc/production/assets/images/flags/${locale.code}.svg`"
            :alt="locale.code"
            class="w-6 mr-4"
          />
          {{ locale.code }}
        </a>
      </li>
    </ul>

    <div class="font-monospace text-4xl mb-6">
      {{ $t('header.currency') }}
    </div>

    <ul class="flex flex-wrap m-0">
      <li v-for="cu in currencies" :key="cu.value" class="mr-8 mb-10">
        <button
          :class="[
            'border py-3 px-6 flex items-center rounded-3xl outline-none focus:outline-none',
            {
              'border-gray-700 text-gray-700 font-medium':
                cu.value === currencyActive,
            },
            {
              'border-gray-300 text-gray-400': cu.value !== currencyActive,
            },
          ]"
          type="button"
          :data-testid="`currency-${cu.value}`"
          @click="changeCurrency(cu.value)"
        >
          <template v-if="cu.symbol">
            {{ cu.symbol }}
          </template>
          {{ cu.value }}
        </button>
      </li>
    </ul>
  </div>
</template>
