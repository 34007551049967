export const useApiHeaders = ({ $api, $httpCookies, $lcRepositories }) => {
  const setHeaders = async () => {
    await $api.setHeader({
      'access-token': $httpCookies.get('access-token'),
      client: $httpCookies.get('client'),
      uid: $httpCookies.get('uid'),
    })

    $lcRepositories.headers.setHeaders({
      accessToken: $httpCookies.get('access-token'),
      client: $httpCookies.get('client'),
      uid: $httpCookies.get('uid'),
    })
  }

  const clearHeaders = () => {
    $api.setHeader({
      'access-token': '',
      client: '',
      uid: '',
    })
    $lcRepositories.headers.clearHeaders()
  }

  return { setHeaders, clearHeaders }
}
