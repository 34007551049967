import revive_payload_client_9EqPcAG4lc from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.0.2_@upstash+redis@_3rcjgzrou727fnif2tlyua7zw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZqFoaZCeai from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.0.2_@upstash+redis@_3rcjgzrou727fnif2tlyua7zw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kdQx7D2FD3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.0.2_@upstash+redis@_3rcjgzrou727fnif2tlyua7zw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fwiBYUUmhz from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@22.0.2_terser@5.3_kw46i6cmgu2rb3nvkraojz5rce/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_sdxZwjJVHe from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.0.2_@upstash+redis@_3rcjgzrou727fnif2tlyua7zw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_IyFF4jFZOh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.0.2_@upstash+redis@_3rcjgzrou727fnif2tlyua7zw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_FDOwLlwHuP from "/vercel/path0/node_modules/.pnpm/nuxt-vercel-analytics@1.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-vercel-analytics/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_LGFKj8rqRu from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.18.0_vue@3.4.31_typescript@5.5.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_l2qBKvhcdN from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.18.0_vue@3.4.31_typescript@5.5.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_dBLwxDLlTU from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_r9EYx5n1xq from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.22.7_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_9BT5RjsiO4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.0.2_@upstash+redis@_3rcjgzrou727fnif2tlyua7zw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_cookies_UxGbIYP5Lj from "/vercel/path0/plugins/00.cookies.ts";
import _01_api_GIiciWaEWX from "/vercel/path0/plugins/01.api.ts";
import _03_lcServices_qQLkq7GlFA from "/vercel/path0/plugins/03.lcServices.ts";
import _04_vuex_Sty52v9tPV from "/vercel/path0/plugins/04.vuex.js";
import _06_gtm_client_7NTV0XxZro from "/vercel/path0/plugins/06.gtm.client.ts";
import _07_getBrowserLanguage_S8Ci00VXAB from "/vercel/path0/plugins/07.getBrowserLanguage.js";
import _08_getUserCurrency_iC9uklmOzO from "/vercel/path0/plugins/08.getUserCurrency.js";
import _09_strapi_api_LaKIr10H54 from "/vercel/path0/plugins/09.strapi-api.js";
import _10_experiment_DuBl1zzse6 from "/vercel/path0/plugins/10.experiment.ts";
import actioncable_client_7QyhyAlEbJ from "/vercel/path0/plugins/actioncable.client.js";
import analytics_client_mZYnNRQBH8 from "/vercel/path0/plugins/analytics.client.js";
import aos_client_rDSYU13oOX from "/vercel/path0/plugins/aos.client.ts";
import clickOutside_client_Zc9fkH3Rh6 from "/vercel/path0/plugins/clickOutside.client.ts";
import maska_client_D8wFsCvpJf from "/vercel/path0/plugins/maska.client.ts";
import vee_validate_K3WwmJMPDb from "/vercel/path0/plugins/vee-validate.js";
export default [
  revive_payload_client_9EqPcAG4lc,
  unhead_ZqFoaZCeai,
  router_kdQx7D2FD3,
  _0_siteConfig_fwiBYUUmhz,
  payload_client_sdxZwjJVHe,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IyFF4jFZOh,
  plugin_client_FDOwLlwHuP,
  switch_locale_path_ssr_LGFKj8rqRu,
  i18n_l2qBKvhcdN,
  plugin_dBLwxDLlTU,
  plugin_r9EYx5n1xq,
  chunk_reload_client_9BT5RjsiO4,
  _00_cookies_UxGbIYP5Lj,
  _01_api_GIiciWaEWX,
  _03_lcServices_qQLkq7GlFA,
  _04_vuex_Sty52v9tPV,
  _06_gtm_client_7NTV0XxZro,
  _07_getBrowserLanguage_S8Ci00VXAB,
  _08_getUserCurrency_iC9uklmOzO,
  _09_strapi_api_LaKIr10H54,
  _10_experiment_DuBl1zzse6,
  actioncable_client_7QyhyAlEbJ,
  analytics_client_mZYnNRQBH8,
  aos_client_rDSYU13oOX,
  clickOutside_client_Zc9fkH3Rh6,
  maska_client_D8wFsCvpJf,
  vee_validate_K3WwmJMPDb
]