<script setup lang="ts">
import { useForm } from 'vee-validate'
import { notValidFormHandler } from '~/helpers/BaseForm/notValidFormHandler'
import { recursiveDeepCopy } from '~/helpers/BaseForm/recursiveDeepCopy'
import { slugify } from '~/helpers/stringHelper.js'

import BaseCheckbox from '~/components/BaseForm/components/BaseCheckbox.vue'
import BaseCheckboxToggle from '~/components/BaseForm/components/BaseCheckboxToggle.vue'
import BaseFileDropInput from './components/BaseFileDropInput.vue'
import BaseFileInput from '~/components/BaseForm/components/BaseFileInput.vue'
import BaseInput from '~/components/BaseForm/components/BaseInput.vue'
import BaseInputNumber from '~/components/BaseForm/components/BaseInputNumber.vue'
import BaseInputPassword from '~/components/BaseForm/components/BaseInputPassword.vue'
import BaseRadioGroup from '~/components/BaseForm/components/BaseRadioGroup.vue'
import BaseTextarea from '~/components/BaseForm/components/BaseTextarea.vue'
import BaseFieldReadMode from '~/components/BaseForm/components/BaseFieldReadMode.vue'
import useFormattedTranslate from '~/components/BaseForm/compose/use-formattedTranslate'
import { useConfigForm } from '~/components/BaseForm/compose/use-config-form'
import { useErrors } from '~/components/BaseForm/compose/use-errors'
import {
  sendMutation,
  type SendMutationType,
} from '~/helpers/BaseForm/sendMutation'
import type { ShallowRef } from 'vue'
import type {
  BaseFormButton,
  BaseFormConfigForm,
  BaseFormMetaOptions,
  Placeholder,
  CoreGroupFields,
  Field,
  InputType,
  MutationType,
} from './types'
import type { ConfigBaseFormModal } from './compose/types'

const unShallowComponents = [
  'calendar',
  'mask-input',
  'multiselect-search',
  'multiselect',
  'tel-input',
]
const components = reactive<
  Partial<Record<InputType, { componentName: ShallowRef }>>
>({
  checkbox: {
    componentName: shallowRef(BaseCheckbox),
  },
  'checkbox-toggle': {
    componentName: shallowRef(BaseCheckboxToggle),
  },
  'file-input': {
    componentName: shallowRef(BaseFileInput),
  },
  'filedrop-input': {
    componentName: shallowRef(BaseFileDropInput),
  },
  input: {
    componentName: shallowRef(BaseInput),
  },
  'input-number': {
    componentName: shallowRef(BaseInputNumber),
  },
  'input-password': {
    componentName: shallowRef(BaseInputPassword),
  },
  'radio-group': {
    componentName: shallowRef(BaseRadioGroup),
  },
  textarea: {
    componentName: shallowRef(BaseTextarea),
  },
})

const props = withDefaults(
  defineProps<{
    activeButtonsSticky?: boolean
    activeErrorScrollTo?: boolean
    activeModalErrors?: boolean
    activeModalSaveOrQuit?: boolean
    buttonPrimary?: BaseFormButton
    buttonSecondary?: BaseFormButton
    containerFormClass?: string
    configForm?: BaseFormConfigForm
    columnMode?: boolean
    containerButtonClass?: string
    disabledPrimaryButton?: boolean
    editionMode?: boolean
    groupFields?: CoreGroupFields[] // groupFieldsValidator(value)
    idForm: string
    initialData?: Record<string, any>
    metaOptions?: BaseFormMetaOptions
    mutation: MutationType<any>
    onSubmit?: () => SendMutationType<any>['onSubmit']
    wrapperButtonClass?: string
  }>(),
  {
    activeButtonsSticky: false,
    activeErrorScrollTo: true,
    activeModalErrors: true,
    activeModalSaveOrQuit: true,
    buttonPrimary: () => ({}),
    buttonSecondary: () => ({}),
    containerFormClass: '',
    configForm: () => ({}),
    columnMode: false,
    containerButtonClass: 'flex flex-col justify-end md:flex-row w-full mt-4',
    disabledPrimaryButton: false,
    editionMode: true,
    groupFields: () => [],
    initialData: () => ({}),
    metaOptions: () => ({}),
    onSubmit: () => ({}),
    wrapperButtonClass: 'base-form-wrapper-button',
  },
)
const emits = defineEmits<{
  'on-cancel': []
  'on-field-update': [string, unknown]
  'on-interdependency': [string, string]
  'on-toggle': [boolean]
}>()

type BaseFormSlots = {
  'before-button'(): any
  'before-container-button'(): any
  'before-container-form'(): any
  'field-read'(p: {
    allData: unknown
    data: Pick<typeof props.initialData, (typeof p)['field']['attr']['name']>
    dataFormatted: unknown
    field: Field
  }): any
  'group-fields'(p: { groupField: CoreGroupFields }): any
} & Record<
  `multiselect-slot-singlelabel-field-${string}`,
  (p: { value: unknown }) => any
> &
  Record<
    `multiselect-slot-option-field-${string}`,
    (p: { option: unknown }) => any
  > &
  Record<
    `after-group-field-${string}`,
    (p: { groupField: CoreGroupFields }) => any
  >
defineSlots<BaseFormSlots>()

const { t } = useI18n()

const refForm = ref<HTMLFormElement | null>(null)
const modalConfig = inject<ConfigBaseFormModal | null>(
  'configBaseFormModal',
  null,
)

const { validate, setErrors, isSubmitting, setFieldError, values, resetForm } =
  useForm({
    validateOnMount: false,
  })
const { handleErrors, scrollToError } = useErrors()

const mutationPending = ref(false)

// Toggle edit mode
watch(
  () => props.editionMode,
  (newValue) => {
    resetData()
    if (editionModeCopy.value) {
      nextTick(() => {
        editionModeCopy.value = newValue
      })

      emits('on-toggle', newValue)
    } else {
      editionModeCopy.value = newValue
      setTimeout(() => {
        setErrors({})
      }, 10)
    }
  },
)

// InitialData
const initialDataMutate = toRef(props, 'initialData')
const configFormMutate = toRef(props, 'configForm')

const stickyButton = ref(false)
const widthStickyContainer = ref(0)

const groupFieldsCopy = ref(props.groupFields)
const editionModeCopy = ref(props.editionMode)
const isSubmitAndValid = ref(false)

const setIsSubmitAndValid = () => {
  isSubmitAndValid.value = true
}

const submit = async () => {
  mutationPending.value = true
  const onSubmit = props.onSubmit()
  const { valid, errors } = await validate()
  const form = refForm.value

  if (initialDataMutate.value.calendar) {
    values.calendar = initialDataMutate.value.calendar
  }

  if (valid) {
    await sendMutation({
      activeErrorScrollTo: props.activeErrorScrollTo,
      form,
      handleErrors,
      metaOptions: props.metaOptions,
      mutation: props.mutation,
      onSubmit,
      refUdapter: setIsSubmitAndValid,
      resetData,
      resetForm,
      scrollToError,
      setFieldError,
      values,
    }).then(() => {
      if (modalConfig?.isOpen.value) {
        modalConfig.changeSection()
      }
      mutationPending.value = false
    })
  } else {
    notValidFormHandler({
      activeErrorScrollTo: props.activeErrorScrollTo,
      errors,
      form,
      scrollToError,
      modalConfig,
      onSubmit,
      setFieldError,
    })
    mutationPending.value = false
  }
}

// copy initialData with all keys
let initialDataCopy = recursiveDeepCopy(initialDataMutate.value)

const updateBaseForm = () => {
  // Translate keys
  useFormattedTranslate(props.groupFields, t)

  // ConfigForm
  useConfigForm(groupFieldsCopy, configFormMutate)
}

// Reset formData + vModel
const resetData = () => {
  // Reset v-model of each field with initialData
  if (isSubmitAndValid.value) {
    initialDataCopy = recursiveDeepCopy(initialDataMutate.value)
    isSubmitAndValid.value = false
  }
  Object.assign(initialDataMutate.value, initialDataCopy)
}

const onCancel = () => {
  resetData()

  if (modalConfig?.isOpen.value) {
    modalConfig.clearData()
  }

  emits('on-cancel')
}

const handleInterdependency = (fieldName: string, boundTo: string) => {
  emits('on-interdependency', fieldName, boundTo)
}

const handleFieldUpdate = (fieldName: string, value: unknown) => {
  emits('on-field-update', fieldName, value)
}

// Computed
const computedContainerButtonClass = computed(() =>
  [
    props.activeButtonsSticky ? 'h-[115px] md:h-[50px]' : '',
    props.containerButtonClass,
  ].filter(String),
)

const dataCyPrimaryButton = computed(() =>
  props.buttonPrimary?.text ? slugify(props.buttonPrimary.text) : '',
)
const dataCySecondaryButton = computed(() =>
  props.buttonSecondary?.text ? slugify(props.buttonSecondary.text) : '',
)
const finalDisabledPrimaryButton = computed(
  () =>
    props.disabledPrimaryButton || isSubmitting.value || mutationPending.value,
)

const toCalendarPlaceholder = (field: Field): Placeholder | undefined => {
  const { placeholder = {} } = field.attr
  if ('startDate' in placeholder && 'endDate' in placeholder)
    return {
      checkIn: String(placeholder.startDate),
      checkOut: String(placeholder.endDate),
    }
  if ('checkIn' in placeholder && 'checkOut' in placeholder)
    return placeholder as Placeholder
}

// Methods
const customProps = (field: Field): Field['attr'] & Partial<Field> => {
  if (field.inputType === 'multiselect-search') {
    return {
      ...field.attr,
      mutationOptions: field.mutationOptions,
    }
  } else if (field.inputType === 'input-autocomplete-google') {
    return {
      ...field.attr,
      // @ts-expect-error Property 'mutationPlace' does not exist on type 'Field'
      mutationPlace: field.mutationPlace,
    }
  } else if (field.inputType === 'checkbox') {
    return {
      ...field.attr,
      fields: field.fields,
    }
  } else if (['radio-group', 'multiselect'].includes(field.inputType)) {
    return {
      ...field.attr,
      options: field.options,
    }
  }

  return {
    ...field.attr,
  }
}
const baseFormGroupClass = (field: CoreGroupFields) =>
  field.containerGroupClass?.toString() || ''
const baseFormGroupFieldsClass = (field: CoreGroupFields) =>
  field.containerGroupFieldsClass?.toString() || ''
const baseFormClass = (field: Field) => field.containerInputClass || ''

const checkConditions = (groupFieldIndex: number, field: Field) => {
  if (field.conditions) {
    const conditionValue = field.conditions.value
    const value = initialDataMutate.value[field?.conditions?.name]

    if (props.groupFields[groupFieldIndex]?.fields) {
      if (Array.isArray(conditionValue)) {
        return conditionValue.includes(String(value))
      } else {
        return conditionValue === value
      }
    }
  }

  return true
}

updateBaseForm()

// Define expose
defineExpose({ updateBaseForm, setIsSubmitAndValid })
</script>

<template>
  <form
    :id="idForm"
    ref="refForm"
    :class="{ 'flex flex-wrap': columnMode }"
    data-testid="baseForm"
    novalidate
    @submit.prevent="submit"
  >
    <slot name="before-container-form" />

    <div :class="containerFormClass">
      <template
        v-for="(groupField, groupFieldIndex) in groupFieldsCopy"
        :key="groupField.title"
      >
        <div v-if="!groupField.hidden" :class="baseFormGroupClass(groupField)">
          <slot name="group-fields" :group-field="groupField">
            <h2
              v-if="groupField.title"
              class="base-form--title text-xl mb-4 text-secondary-300"
              date-testid="baseForm-title"
            >
              {{ groupField.title }}
            </h2>
          </slot>

          <p
            v-if="groupField.description"
            class="base-form--description w-full italic mb-6"
            date-testid="baseForm-description"
          >
            {{ groupField.description }}
          </p>

          <div :class="baseFormGroupFieldsClass(groupField)">
            <template v-for="field in groupField.fields" :key="field.attr.name">
              <div
                v-if="checkConditions(groupFieldIndex, field) && !field.hidden"
                :class="baseFormClass(field)"
              >
                <template v-if="!editionModeCopy">
                  <BaseFieldReadMode
                    :all-data="initialDataMutate"
                    :data="initialDataMutate[field.attr.name]"
                    :field="field"
                    :view-mode-row="field.viewModeRow"
                  >
                    <template #value="{ dataFormatted, allData }">
                      <slot
                        name="field-read"
                        :all-data="allData"
                        :data="initialDataMutate[field.attr.name]"
                        :data-formatted="dataFormatted"
                        :field="field"
                      />
                    </template>
                  </BaseFieldReadMode>
                </template>

                <template v-else>
                  <LazyBaseFormComponentsBaseCalendar
                    v-if="field.inputType === 'calendar'"
                    v-model:end-date="
                      initialDataMutate[field.attr.name].endDate
                    "
                    v-model:start-date="
                      initialDataMutate[field.attr.name].startDate
                    "
                    v-bind="field.attr"
                    :placeholder="toCalendarPlaceholder(field)"
                    :data-cy="field.attr.name"
                    :data-testid="field.attr.name"
                  />

                  <LazyBaseFormComponentsBaseTelInput
                    v-if="field.inputType === 'tel-input'"
                    v-model="initialDataMutate[field.attr.name]"
                    :data-cy="field.attr.name"
                    :data-testid="field.attr.name"
                    :info-text="field.infoText"
                    v-bind="customProps(field)"
                    @trigger-interdependency="handleInterdependency"
                    @update:model-value="
                      handleFieldUpdate(field.attr.name, $event)
                    "
                  />

                  <LazyBaseFormComponentsBaseMultiselect
                    v-if="field.inputType === 'multiselect'"
                    v-model="initialDataMutate[field.attr.name]"
                    :data-cy="field.attr.name"
                    :data-testid="field.attr.name"
                    :info-text="field.infoText"
                    v-bind="customProps(field)"
                    @trigger-interdependency="handleInterdependency"
                    @update:model-value="
                      handleFieldUpdate(field.attr.name, $event)
                    "
                  />
                  <LazyBaseFormComponentsBaseMultiselectSearch
                    v-if="field.inputType === 'multiselect-search'"
                    v-model="initialDataMutate[field.attr.name]"
                    :data-cy="field.attr.name"
                    :data-testid="field.attr.name"
                    :info-text="field.infoText"
                    v-bind="customProps(field)"
                    @trigger-interdependency="handleInterdependency"
                    @update:model-value="
                      handleFieldUpdate(field.attr.name, $event)
                    "
                  />
                  <LazyBaseFormComponentsBaseMaskInput
                    v-if="field.inputType === 'mask-input'"
                    v-model="initialDataMutate[field.attr.name]"
                    :data-cy="field.attr.name"
                    :data-testid="field.attr.name"
                    :info-text="field.infoText"
                    v-bind="customProps(field)"
                    :placeholder="String(field.attr.placeholder)"
                    @trigger-interdependency="handleInterdependency"
                    @update:model-value="
                      handleFieldUpdate(field.attr.name, $event)
                    "
                  />

                  <component
                    :is="components[field.inputType]?.componentName"
                    v-if="!unShallowComponents.includes(field.inputType)"
                    v-model="initialDataMutate[field.attr.name]"
                    :data-cy="field.attr.name"
                    :data-testid="field.attr.name"
                    :info-text="field.infoText"
                    v-bind="customProps(field)"
                    @trigger-interdependency="handleInterdependency"
                    @update:model-value="
                      handleFieldUpdate(field.attr.name, $event)
                    "
                  >
                    <template
                      v-if="field.inputType === 'multiselect'"
                      #multiselect-singlelabel="{ value }"
                    >
                      <slot
                        :name="`multiselect-slot-singlelabel-field-${field.attr.name}`"
                        :value="value"
                      />
                    </template>

                    <template
                      v-if="field.inputType === 'multiselect'"
                      #multiselect-option="{ option }"
                    >
                      <slot
                        :name="`multiselect-slot-option-field-${field.attr.name}`"
                        :option="option"
                      />
                    </template>
                  </component>
                </template>
              </div>
            </template>
          </div>
          <slot
            v-if="groupField.key"
            :name="`after-group-field-${groupField.key}`"
            :group-field="groupField"
          />
        </div>
      </template>
    </div>

    <slot name="before-container-button" />

    <div
      v-if="
        editionModeCopy &&
        (Object.keys(buttonSecondary).length > 0 ||
          Object.keys(buttonPrimary).length > 0)
      "
      :class="computedContainerButtonClass"
    >
      <div
        :class="[
          wrapperButtonClass,
          { 'base-form-wrapper-button-fixed': stickyButton },
        ]"
        :style="[stickyButton ? { width: `${widthStickyContainer}px` } : '']"
      >
        <slot name="before-button" />

        <BaseButton
          v-if="Object.keys(buttonSecondary).length > 0"
          data-testid="baseform-button-secondary"
          :data-cy="dataCySecondaryButton"
          type="button"
          v-bind="buttonSecondary.attrs"
          :variant="buttonSecondary?.attrs?.variant || 'outline'"
          @click="onCancel"
        >
          <BaseIcon
            v-if="buttonSecondary?.attrs?.icon"
            v-bind="buttonSecondary.attrs.icon"
          />
          {{ buttonSecondary.text }}
        </BaseButton>

        <BaseButton
          v-if="Object.keys(buttonPrimary).length > 0"
          data-testid="baseform-button-primary"
          :data-cy="dataCyPrimaryButton"
          :disabled="finalDisabledPrimaryButton"
          name="submit"
          type="submit"
          v-bind="buttonPrimary.attrs"
        >
          <BaseIcon
            v-if="buttonPrimary?.attrs?.icon"
            v-bind="buttonPrimary.attrs.icon"
          />
          <span>{{ buttonPrimary.text }}</span>
        </BaseButton>
      </div>
    </div>
  </form>
</template>

<style>
.base-form-label {
  @apply block;
}
.base-form--error {
  @apply text-sm text-error;
}
.base-field-info + .base-form--error {
  @apply mt-6;
}
.base-form--hasError {
  @apply border-error;
}
.base-form-wrapper-button {
  @apply flex flex-col md:flex-row;
}
.base-form-wrapper-button-fixed {
  /* filestack picker have z-index 2147483647, need apply stronger */
  @apply fixed z-[2147483648] bottom-0 py-4 bg-white md:justify-end;
}
</style>
