<script setup lang="ts">
import TheHeaderSearchInput from './TheHeaderSearchInput.vue'
import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import type { RouteLocation } from 'vue-router'
import type { HouseSlug, Locale } from 'lc-services/types'

const emits = defineEmits<{
  'link-clicked': [RouteLocation]
}>()
const { t, locale } = useI18n()
const localePath = useLocalePath()
const { headerMobile } = useHeaderMobile()
const { headerDesktop } = useHeaderDesktop()

const destinationsResults = ref([])
const destinationsSuggestions = ref([
  {
    name: 'Saint-Tropez',
    image:
      'https://cdn.lecollectionist.com/__lecollectionist__/production/destinations/84/ildljGvR1avhCXCGsvj4_2f4cce3d-f416-42cc-f7a3-aa3c73d721a7.jpg',
    cluster: {
      en: 'French Riviera',
      fr: "Côte d'Azur",
    },
    href: 'destinationId=84',
    search: {
      name: {
        en: 'Saint-Tropez & surroundings',
        fr: 'Saint-Tropez & environs',
      },
      destinationId: 84,
    },
  },
  {
    name: 'Paros & Antiparos',
    image:
      'production/uploads/photos/house-5768/2022-05-31-51060f05821808167053ba264c4ea3b9.jpg',
    cluster: {
      en: 'Cyclades',
      fr: 'Iles des Cyclades',
    },
    href: 'destinationId=26',
    search: {
      name: { en: 'Paros & Antiparos', fr: 'Paros & Antiparos' },
      destinationId: 26,
    },
  },
  {
    name: 'Cap Ferret',
    image:
      'https://cdn.lecollectionist.com/lc/production/uploads/photos/destination-60/2019-12-04-09164db5411bb02d7cd6c8ad52cb811f.jpg',
    cluster: {
      en: 'Southwest, France',
      fr: 'Sud-Ouest, France',
    },
    href: 'destinationId=59',
    search: {
      name: { en: 'Cap Ferret, Southwest', fr: 'Cap Ferret, Sud-Ouest' },
      destinationId: 59,
    },
  },
])
const hasQuery = ref(false)
const searchQuery = ref('')
const housesResults = ref([])
const housesSuggestions = ref([
  {
    id: 3861,
    name: 'Villa Allure',
    image:
      'production/uploads/photos/house-3861/2020-12-02-5a22f554e99d28c3f6e5767ca50ee891.jpg',
    cluster: {
      en: 'Saint-Remy-de-Provence & surroundings',
      fr: 'Saint-Rémy-de-Provence & environs',
    },
    href: {
      en: 'luxury-rental/villa-allure-saint-remy-de-provence-surroundings',
      fr: 'location-luxe/villa-allure-saint-remy-de-provence-environs',
    },
  },
  {
    id: 4517,
    name: 'Villa Ariane',
    image:
      'production/uploads/photos/house-4517/2021-06-14-2f14d205998f39e35fd19a91456a5984.jpg',
    cluster: {
      en: 'Antiparos',
      fr: 'Antiparos',
    },
    href: {
      en: 'luxury-rental/villa-ariane-antiparos',
      fr: 'location-luxe/villa-ariane-antiparos',
    },
  },
  {
    id: 3708,
    name: 'Villa Dune',
    image:
      'https://cdn.lecollectionist.com/__lecollectionist__/production/houses/3708/photos/Y5o5P4cITlOaqtv8XQbA_4f8bd164-e006-4c3a-c008-36471ff2773a.jpg',
    cluster: {
      en: 'Cap Ferret',
      fr: 'Cap Ferret',
    },
    href: {
      en: 'luxury-rental/villa-dune-cap-ferret',
      fr: 'location-luxe/villa-dune-cap-ferret',
    },
  },
])
const showInputSearch = ref(false)
const activeDropdown = computed(() => headerDesktop.value.activeDropdown)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)
const hasDestinations = computed(() => destinations.value.length > 0)
const hasHouses = computed(() => houses.value.length > 0)
const destinations = computed(() =>
  hasQuery.value
    ? destinationsResults.value
    : destinationsSuggestionsFormated.value,
)
const destinationsSuggestionsFormated = computed(() => {
  const d: typeof housesSuggestions extends Ref<infer T> ? T : never =
    JSON.parse(JSON.stringify(destinationsSuggestions.value))

  return d.map((desti) => ({
    ...desti,
    href: `${localePath({ name: 'search' })}?${desti.href}`,
    subName: desti.cluster[locale.value as Locale],
  }))
})
const destinationsTitle = computed(() =>
  hasQuery.value
    ? t('headerSearch.destinations')
    : t('headerSearch.topDestinations'),
)
const houses = computed(() =>
  hasQuery.value ? housesResults.value : housesSuggestionsFormated.value,
)
const housesSuggestionsFormated = computed(() => {
  const houses: typeof housesSuggestions extends Ref<infer T> ? T : never =
    JSON.parse(JSON.stringify(housesSuggestions.value))

  return houses.map((house) => ({
    ...house,
    href: getPathDestination(house.href),
    subName: house.cluster[locale.value as Locale],
  }))
})
const housesTitle = computed(() =>
  hasQuery.value
    ? t('headerSearch.houses')
    : t('headerSearch.housesOurSelection'),
)
const searchOpened = computed(
  () => activeDropdown.value?.name === 'search' || searchIsOpen.value,
)
const title = computed(() =>
  hasQuery.value ? t('headerSearch.results') : t('headerSearch.suggestions'),
)

watch(
  () => searchOpened.value,
  (val) => {
    if (val) {
      showInputSearch.value = true
    } else {
      showInputSearch.value = false

      // wait until dropdown closed for reset values
      setTimeout(() => {
        setHasQuery(false)
        setDestinations([])
        setHouses([])
      }, 300)
    }
  },
)

const getPathDestination = (destinationNames: HouseSlug) =>
  getDestinationSlug(destinationNames)[locale.value as Locale].path
const linkClicked = (route: RouteLocation) => {
  emits('link-clicked', route)
}
const setDestinations = (
  result: typeof destinationsResults extends Ref<infer T> ? T : never,
) => {
  destinationsResults.value = result
}
const setHasQuery = (result: boolean, query = '') => {
  searchQuery.value = query
  hasQuery.value = result
}
const setHouses = (
  result: typeof housesResults extends Ref<infer T> ? T : never,
) => {
  housesResults.value = result
}
</script>

<template>
  <div class="laptop:grid laptop:grid-cols-12">
    <div class="laptop:col-span-8 laptop:col-start-3">
      <the-header-search-input
        v-if="showInputSearch"
        @has-query="setHasQuery"
        @houses="setHouses"
        @destinations="setDestinations"
      />
      <!-- Placeholder -->
      <div
        v-else
        class="relative mb-6 laptop:mb-14 h-[58px] rounded-full bg-gray-100"
      />

      <p
        class="text-gray-700 text-4xl laptop:text-6xl leading-none font-bold font-monospace mb-6"
      >
        {{ title }}
      </p>

      <div class="flex flex-col laptop:flex-row">
        <the-header-search-group-links
          :class="[
            'laptop:w-1/2 mb-6 laptop:mb-0 destinations',
            {
              'order-1 md:order-none': hasDestinations,
              'order-2 md:order-none': !hasDestinations && hasHouses,
            },
          ]"
          :links="destinations"
          :search-query="searchQuery"
          :title="destinationsTitle"
          @link-clicked="linkClicked"
        />

        <div
          class="hidden laptop:block flex-shrink w-32 relative search-group-links-sep"
        />

        <the-header-search-group-links
          :class="[
            'laptop:w-1/2 houses',
            {
              'order-1 mb-6 md:order-none': !hasDestinations && hasHouses,
              'order-2 md:order-none': hasDestinations,
            },
          ]"
          :links="houses"
          :search-query="searchQuery"
          :title="housesTitle"
          @link-clicked="linkClicked"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-group-links-sep:before {
  content: '';
  transform: rotate(15deg);
  @apply border-r border-gray-200 h-full w-px top-0 absolute origin-bottom;
}
</style>
