<script setup lang="ts">
import { aboutLinks } from '~/assets/js/aboutLinks'

defineEmits<{
  'close-menu-mobile': []
}>()

const localePath = useLocalePath()
const links = ref(aboutLinks)
</script>

<template>
  <div class="px-4 py-8 tablet:px-12 tablet:py-10">
    <div class="mb-10 text-center border-b border-gray-200">
      <p class="mb-8">
        {{ $t('headerAbout.joinCollection') }}
      </p>
      <base-button
        class="mb-10"
        color="secondary"
        :to="localePath('owners')"
        @click="$emit('close-menu-mobile')"
      >
        {{ $t('headerAbout.cta') }}
      </base-button>
    </div>

    <div
      class="mb-6 text-2xl leading-none tablet:text-4xl font-monospace tablet:mb-8"
    >
      {{ $t('headerAbout.universe') }}
    </div>

    <ul class="tablet:flex tablet:flex-wrap">
      <li
        v-for="(link, index) in links"
        :key="index"
        class="flex items-center mb-4 tablet:w-1/2"
      >
        <div class="w-[60px] h-[60px] shrink-0">
          <NuxtLink
            :to="localePath(link.href)"
            class="flex-none rounded-md block"
            @click="$emit('close-menu-mobile')"
          >
            <BaseNuxtImg
              :alt="$t(link.text)"
              :src="link.img"
              height="60"
              loading="lazy"
              width="60"
            />
          </NuxtLink>
        </div>

        <base-button
          :to="localePath(link.href)"
          class="flex-grow screen-about-link"
          color="secondary-black"
          data-testid="screen-about__link"
          font-weight="font-normal"
          variant="link"
          @click="$emit('close-menu-mobile')"
        >
          {{ $t(link.text) }}
        </base-button>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.screen-about-link {
  @apply pl-7;
}
</style>
