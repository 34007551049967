import type { VariantKey } from '~/types/amplitude/types'
export type ExperimentTranslation = {
  [key: string]: {
    [key: VariantKey]: {
      [key: string]: string
    }
  }
}

export const experimentTranslations = <
  {
    en: ExperimentTranslation
    fr: ExperimentTranslation
  }
>{
  en: {
    test: {
      control: {
        debug: 'CONTROL',
      },
      treatment: {
        debug: 'TREATMENT',
      },
    },
    'pre-reservation-nouveau-wording-cta': {
      control: {
        modalAuthTextHeader:
          'Please log in to pre-book the property for the selected period and block its availability.',
        preOrderCTA: 'Pre-booking',
        preOrderDescription:
          'The « pre-booking » feature allows you to secure the dates selected for a property. Our team will contact you to confirm the pre-booking request as soon as possible.',
        preOrderTitle: 'Pre-booking',
        preOrderPayTheDepositOf: 'Pay the pre-booking fee of ',
        preOrderDepositAmount: 'Pre-booking fee',
        preOrderStepTitleDepositPayment: 'Pre-booking payment',
        preOrderTermsAndConditionsText: '',
        preOrderSuccessBookingNo: 'Your pre-booking no. is:',
        preOrderCancelText: 'Are you sure you want to cancel the pre-booking?',
        preOrderSuccessSubtitle:
          '**Thank you,** your payment has been successful and the house is pre-booked for you on these dates.',
        preOrderSuccessProvideAllDetails:
          'We will provide you with all the details of your request in a summary e-mail.',
      },
      treatment: {
        modalAuthTextHeader:
          "Please authenticate to block the property's availability for the selected dates.",
        preOrderCTA: 'Book',
        preOrderDescription:
          'By continuing, you will block the availability of the property for the selected dates. **Please note, your booking is not yet confirmed.** Our team will contact you as soon as possible to finalise the details, and the deposit amount will be deducted from the total once the booking is confirmed.',
        preOrderTitle: 'Booking',
        preOrderPayTheDepositOf: 'Pay the deposit of ',
        preOrderDepositAmount: 'Deposit amount',
        preOrderStepTitleDepositPayment: 'Payment of the deposit',
        preOrderTermsAndConditionsText:
          'Paying a deposit for a stay allows you to reserve, during 24 hours, the property for the specified dates. You will be contacted by an advisor within 2 hours during office hours or within 24 hours outside of office hours.\n\n**This deposit does not constitute a reservation.** An advisor must first confirm your stay, and you will then need to accept the booking contract and the general and specific terms.\n\n**If the reservation is confirmed, the amount paid as a deposit will be deducted from the total amount of the booking. If the reservation is not confirmed, the deposit paid will be fully refunded within a maximum of 48 hours.** The Collectionist reserves the right to refuse any reservation.',
        preOrderSuccessBookingNo: 'Your booking reference number is:',
        preOrderCancelText: 'Are you sure you want to cancel the booking?',
        preOrderSuccessSubtitle:
          'Thank you, your payment has been successful and the house is booked for you on these dates.',
        preOrderSuccessProvideAllDetails:
          'We will provide you with all the details of your request in a summary e-mail.',
      },
    },
  },
  fr: {
    test: {
      control: {
        debug: 'CONTROL',
      },
      treatment: {
        debug: 'TREATMENT',
      },
    },
    'pre-reservation-nouveau-wording-cta': {
      control: {
        modalAuthTextHeader:
          'Veuillez vous authentifier pour réaliser une pré-réservation sur cette période et bloquer les disponibilités de la propriété pour les dates sélectionnées.',
        preOrderCTA: 'Pré-réserver',
        preOrderDescription:
          'La fonctionnalité « de pré-réservation » vous permet de bloquer les disponibilités de la propriété pour les dates sélectionnées. Vous serez recontacté(e) par notre équipe afin de confirmer la pré-réservation dans les plus brefs délais.',
        preOrderTitle: 'Pré-réservation',
        preOrderPayTheDepositOf: 'Payer la pré-réservation de ',
        preOrderDepositAmount: 'Montant de la pré-réservation',
        preOrderStepTitleDepositPayment: 'Paiement de la pré-réservation',
        preOrderTermsAndConditionsText: '',
        preOrderSuccessBookingNo:
          'Votre numéro de pré-réservation est le suivant :',
        preOrderCancelText:
          'Êtes-vous sûr(e) de vouloir abandonner la pré-réservation ?',
        preOrderSuccessSubtitle:
          '**Merci,** votre paiement a été pris en compte et la maison est pré-réservée pour vous à ces dates.',
        preOrderSuccessProvideAllDetails:
          'Vous trouverez tous les détails de votre demande dans un e-mail récapitulatif.',
      },
      treatment: {
        modalAuthTextHeader:
          'Veuillez vous authentifier pour bloquer la disponibilité de la propriété aux dates sélectionnées.',
        preOrderCTA: 'Réserver',
        preOrderDescription:
          "En continuant, vous bloquerez la disponibilité de la propriété pour les dates sélectionnées. **Attention, votre réservation n’est pas encore confirmée.** Notre équipe vous contactera dans les plus brefs délais pour finaliser les détails, et le montant de l'acompte sera déduit du total une fois la réservation confirmée.",
        preOrderTitle: 'Réservation',
        preOrderPayTheDepositOf: 'Payer l’acompte de',
        preOrderDepositAmount: 'Montant de l’acompte',
        preOrderStepTitleDepositPayment: 'Paiement de l’acompte',
        preOrderTermsAndConditionsText:
          "Verser un acompte pour un séjour vous permet de réserver, pendant 24h, la propriété pour les dates renseignées. Vous serez recontacté(e) dans un délai de 2h durant les horaires d'ouverture de bureau ou de 24h hors horaires d'ouverture de bureau.\n\n**Cet acompte ne vaut pas réservation.** Un conseiller doit d’abord confirmer votre séjour et vous devrez ensuite accepter le contrat de réservation et les conditions générales et particulières.\n\n**Si la réservation est confirmée, les sommes versées en acompte seront déduites du montant total de la réservation. Si la réservation n'est pas confirmée, l'acompte versé sera intégralement restitué dans un délai maximum de 48h.** Le Collectionist se réserve le droit de refuser toute réservation.",
        preOrderSuccessBookingNo:
          'Votre numéro de réservation est le suivant :',
        preOrderCancelText:
          'Êtes-vous sûr(e) de vouloir abandonner la réservation ?',
        preOrderSuccessSubtitle:
          'Merci, votre paiement a été pris en compte et la maison est réservée pour vous à ces dates.',
        preOrderSuccessProvideAllDetails:
          'Vous trouverez tous les détails de votre demande dans un e-mail récapitulatif.',
      },
    },
  },
}
