import { ABTestService } from 'lc-repository'

import type { Experiment, UserVariant } from '~/types/amplitude/types'
import type { Auth } from '~/types/types'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const { $httpCookies } = nuxtApp as any

  const auth = useState<Auth>('auth')
  const experiments = useState<Experiment[]>('experiments', () => [])
  const userVariant = useState<UserVariant>('user-variant')

  const ABTest = new ABTestService({
    amplitudeLabApiUrl: 'https://api.lab.eu.amplitude.com',
    experimentApiUrl: 'https://experiment.eu.amplitude.com',
    experimentApiKey: config.public.experimentApiKey,
    amplitudeApiUrl: 'https://api.eu.amplitude.com',
    amplitudeApiKey: config.public.amplitudeApiKey,
    httpClientType: 'ofetch',
  })

  const experimentCookie = $httpCookies.get('_dcid') || $httpCookies.get('_amp')
  const experimentServerInit = async () => {
    experiments.value =
      (await ABTest.getExperiments()) as unknown as Experiment[]
    userVariant.value = (await ABTest.getUserVariants({
      userId: auth?.value?.user?.id ? `user_${auth.value.user.id}` : undefined,
      deviceId: experimentCookie,
    })) as unknown as UserVariant
  }

  return {
    provide: {
      experimentServerInit,
    },
  }
})
