<script setup lang="ts">
import type { NuxtError } from '#app'

definePageMeta({
  gtmAdditionalEventData: { page_type: 'error 404' },
})
withDefaults(
  defineProps<{
    error?: NuxtError
  }>(),
  {
    error: () => ({}) as NuxtError,
  },
)

const { isDesktop } = useBreakpoint()
const localePath = useLocalePath()

const bgError = computed(() =>
  isDesktop.value
    ? 'https://cdn.lecollectionist.com/lc/production/assets/images/error/photo-page-erreur-404-desktop.jpg'
    : 'https://cdn.lecollectionist.com/lc/production/assets/images/error/photo-page-erreur-404-mobile.jpg',
)
</script>

<template>
  <div
    :style="{ backgroundImage: `url(${bgError})` }"
    class="background-error flex flex-1 bg-cover py-16 min-h-[600px] laptop:min-h-[750px]"
  >
    <div class="lc-container flex flex-col">
      <div class="mt-0 md:mt-14 text-center md:text-left">
        <p class="uppercase tracking-widest mb-7 md:mb-4">
          {{ $t('error_layout.error404') }}
        </p>
        <h1 class="mb-6 text-4xl md:text-8xl font-normal max-w-[650px]">
          {{ $t('error_layout.title') }}
        </h1>
        <base-button color="secondary" :to="localePath('index')" size="lg">
          {{ $t('error_layout.btn') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-error {
  background-position: 70% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@screen tablet {
  .background-error {
    background-position: 100% 70%;
  }
}

@screen desktop {
  .background-error {
    background-position: 100% 100%;
  }
}
</style>
