<script setup lang="ts">
import type { Experiment, UserVariant } from '~/types/amplitude/types'

const show = ref(false)
const config = useRuntimeConfig()

const experiments = useState<Experiment[]>('experiments')
const userVariant = useState<UserVariant>('user-variant')

const changeVariant = (flagKey: string) => {
  const variant = userVariant.value[flagKey]

  userVariant.value[flagKey] = variant === 'control' ? 'treatment' : 'control'
}
</script>

<template>
  <div>
    <div
      :class="[
        'fixed bottom-2 left-2 bg-white border border-gray-200 rounded-[12px] text-sm z-[9999] w-80 max-h-[80vh] overflow-auto shadow-md',
        {
          hidden: !show,
        },
      ]"
    >
      <div
        class="bg-white pt-4 px-4 sticky top-0 text-xl font-bold flex items-center justify-between mb-3"
      >
        <div><span>Experiment</span> <span class="ml-1">🧬</span></div>
        <button type="button" @click="show = false">
          <BaseIcon name="close" />
        </button>
      </div>

      <div class="px-4 pb-2">
        <div>
          <p class="text-base font-bold mb-2 text-gray-700">
            Active experiments:
          </p>
          <div
            v-for="e in experiments"
            :key="e.id"
            class="mb-2 pb-2 border-b border-gray-200 last:border-b-0 text-md"
          >
            <div class="pl-2">
              <p class="mb-0">
                <span class="font-semibold text-gray-700">Id:</span
                >&nbsp;<span>{{ e.id }}</span>
              </p>
              <p class="mb-0">
                <span class="font-semibold text-gray-700">Key:</span
                >&nbsp;<span>{{ e.key }}</span>
              </p>
              <div class="mb-0">
                <span class="font-semibold text-gray-700">Variants:</span>&nbsp;
                <span v-if="e?.variants">
                  {{ e.variants.map((x) => x.key).join(', ') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <p class="text-base font-bold mb-2 text-gray-700">User variant:</p>
          <template v-if="Object.keys(userVariant).length">
            <div
              v-for="[flagKey, variant] of Object.entries(userVariant)"
              :key="flagKey"
              class="flex items-center pt-2 mb-2 pb-2 border-b border-gray-200 last:border-b-0 text-sm"
            >
              <BaseTooltip
                position="right"
                rounded
                show
                size="small"
                class="block"
              >
                <template #element>
                  <button
                    type="button"
                    class="w-6 h-6 mr-2"
                    @click="changeVariant(flagKey)"
                  >
                    <BaseIcon
                      :size="2"
                      :name="variant === 'control' ? 'switchOff' : 'switchOn'"
                    />
                  </button>
                </template>
                <template #text>
                  Change to
                  {{ variant === 'control' ? 'treatment' : 'control' }}
                </template>
              </BaseTooltip>

              <p class="font-semibold text-gray-700">
                {{ flagKey }}:&nbsp;
                <span class="text-primary-700">{{ variant }}</span>
              </p>
            </div>
          </template>
          <template v-else>
            <p class="text-[11px]">No loaded yet</p>
          </template>
        </div>

        <div v-if="config.public.nodeEnv === 'development'" class="mt-2">
          <p class="text-base font-bold mb-2 text-gray-700">
            Test translations:
          </p>
          <p class="pl-2 font-semibold text-gray-700 text-md">
            {{ useExperimentTranslations('debug') }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!show">
      <button
        type="button"
        class="fixed bottom-2 left-2 py-2 px-3 bg-white border border-gray-200 rounded-full text-sm z-[9999] shadow-md font-semibold cursor-pointer"
        @click="show = true"
      >
        <span>Experiment</span> <span class="ml-1">🧬</span>
      </button>
    </div>
  </div>
</template>
