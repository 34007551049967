<script setup lang="ts">
import { useStore } from 'vuex'
import type { NuxtError } from '#app'
import type { Advisor } from '~/types/reservationAgreement/types'

defineProps<{
  data: {
    advisor: Advisor
    currentEmail: string
    expectedEmail: string
    propertyName: string
    propertyPhoto: string
  }
  error: NuxtError
}>()

const route = useRoute()
const store = useStore()
const { clearUser } = useUser()

const onLogout = () => {
  store.dispatch('auth/logout', {
    redirect: true,
    returnTo: route.fullPath,
  })

  clearUser()
}
</script>

<template>
  <div class="lc-container md:flex my-8 md:my-28 items-center">
    <div class="w-full md:w-2/3">
      <div class="w-full md:w-4/5">
        <h1 class="text-4xl md:text-7xl mb-8">
          {{ $t('errorPage.contractBadAccount.title') }}
        </h1>

        <i18n-t
          class="m-0"
          keypath="errorPage.contractBadAccount.text1"
          tag="p"
        >
          <template #email>
            <span class="text-gray-500">{{ data.currentEmail }}</span>
          </template>
        </i18n-t>

        <i18n-t
          class="m-0"
          keypath="errorPage.contractBadAccount.text2"
          tag="p"
        >
          <template #propertyName>
            <span class="font-medium">{{ data.propertyName }}</span>
          </template>
        </i18n-t>

        <i18n-t
          class="mb-8"
          keypath="errorPage.contractBadAccount.text3"
          tag="p"
        >
          <template #email>
            <span class="text-gray-500">{{ data.expectedEmail }}</span>
          </template>
        </i18n-t>

        <base-button
          color="secondary"
          data-testid="logout"
          data-cy="logout"
          @click="onLogout"
        >
          {{ $t('account.logout') }}
        </base-button>
      </div>
    </div>
    <div class="hidden md:block md:w-1/3">
      <BaseNuxtImg
        :alt="data.propertyName"
        width="500"
        height="500"
        :src="data.propertyPhoto"
        class="rounded"
      />
    </div>
  </div>
</template>
