/*
 * Composable for change url custom on switchLocalePath
 */

export const useSetRouteParams = () => {
  const nuxt = useNuxtApp()
  const { $i18n } = nuxt
  const routeParams = useState('routeParams')

  const setRouteParams = ({
    slugFr = '',
    slugEn = '',
  }: {
    slugFr: string
    slugEn: string
  }) => {
    routeParams.value = {
      en: { slug: slugEn },
      fr: { slug: slugFr },
    }
  }

  const getNewUrl = (localeCode: 'fr' | 'en') => {
    const currentLocaleRouteSlug = routeParams.value?.[$i18n.locale.value]?.slug
    let nextLocaleRouteSlug = nuxt.$switchLocalePath(localeCode)
    if (currentLocaleRouteSlug) {
      nextLocaleRouteSlug = nuxt
        .$switchLocalePath(localeCode)
        .replace(currentLocaleRouteSlug, routeParams.value[localeCode].slug)
    }

    return nextLocaleRouteSlug
  }

  return { getNewUrl, setRouteParams }
}
