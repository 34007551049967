import type { Locale } from 'lc-services/types/common/search'
import { buildRepositories } from 'lc-repository'
import { searchBuild } from 'lc-services/modules/search/searchBuild'
import { authBuild } from 'lc-services/modules/auth/authBuild'
import { wishlistBuild } from 'lc-services/modules/wishlist'
import { automaticProposalsBuild } from 'lc-services/modules/automaticProposals/automaticProposalsBuild'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { $httpCookies } = nuxtApp as any
  const $i18n = nuxtApp.$i18n as {
    locale: Ref<Locale>
  }
  const locale: Ref<Locale> = $i18n.locale
  const API_BASE_URL = config.public.apiURL as string

  const lcRepositories = buildRepositories.lc({
    api: {
      name: 'lc',
      url: API_BASE_URL,
      source: 'website',
    },
    locale: locale.value,
    httpClientType: 'ofetch',
    storageClient: {
      getItem: (key: string) => {
        // console.log('lc-repo getItem key', key)

        if (key === 'accessToken') {
          return Promise.resolve($httpCookies.get('access-token') as string)
        }

        return Promise.resolve($httpCookies.get(key) as string)
      },
      setItem: (key: string, value: string) => {
        // console.log('lc-repo setItem key', key, value)

        if (key === 'accessToken') {
          return Promise.resolve(
            $httpCookies.set('access-token', value) as string,
          )
        }

        return Promise.resolve($httpCookies.set(key, value) as string)
      },
    },
  })

  watch(
    () => locale.value,
    (newValue) => {
      lcRepositories.localeCustom.setLocale(newValue)
    },
  )

  const { controller: searchController, presenter: searchPresenter } =
    searchBuild({
      repository: lcRepositories,
    })

  const { controller: authController, presenter: authPresenter } = authBuild(
    lcRepositories.auth,
    lcRepositories.user.auth,
    lcRepositories.user,
  )
  const { controller: wishlistController, presenter: wishlistPresenter } =
    wishlistBuild(
      lcRepositories.user,
      lcRepositories.multiInquiryWishlists,
      lcRepositories.requestWishlists,
    )

  const {
    controller: automaticProposalController,
    presenter: automaticProposalPresenter,
  } = automaticProposalsBuild({
    automaticProposalsRepository: lcRepositories,
    userRepository: lcRepositories.user,
  })

  return {
    provide: {
      lcRepositories,
      lcServicesAuth: {
        authPresenter,
        authController,
      },
      lcServicesAutomaticProposals: {
        automaticProposalController,
        automaticProposalPresenter,
      },
      lcServicesSearch: {
        searchController,
        searchPresenter,
      },
      lcServicesWishlist: {
        wishlistController,
        wishlistPresenter,
      },
    },
  }
})
