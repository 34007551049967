<script setup lang="ts">
import type { Notification } from '~/composables/useNotifications'

defineOptions({
  inheritAttrs: false,
})

const emits = defineEmits<{
  close: []
}>()

const props = withDefaults(defineProps<Partial<Omit<Notification, 'id'>>>(), {
  duration: 5000,
  link: null,
  message: '',
  showCloseButton: true,
  title: '',
  toastClass: '',
  type: 'primary',
})

const show = ref(false)

const close = () => {
  emits('close')
}

onMounted(() => {
  // Show toast after 500 ms
  setTimeout(() => {
    show.value = true
  }, 500)

  // Remove toast after timeout ms
  setTimeout(() => {
    show.value = false
  }, props.duration)

  // Remove container after animation end
  setTimeout(() => {
    close()
  }, props.duration + 500)
})

const isPrimary = computed(() => props.type === 'primary')
const isSecondary = computed(() => props.type === 'secondary')
</script>

<template>
  <div
    :class="[
      'base-toast-item opacity-0 fixed h-fit w-full md:w-max px-4 md:px-0 bottom-6 md:top-[calc(74px+1rem)] md:right-6 md:max-w-sm z-modal',
      {
        show: show,
        'border border-gray-300 rounded-sm': isPrimary,
      },
    ]"
    data-cy="toast-item"
  >
    <div
      :class="[
        'flex items-center justify-between',
        {
          'p-3 bg-white': isPrimary,
          'p-4 bg-gray-700 rounded': isSecondary,
        },
      ]"
    >
      <div>
        <div
          v-if="title"
          :class="[
            'mb-1 text-base font-black leading-5',
            {
              'text-gray-700': isPrimary,
              'text-white': isSecondary,
            },
          ]"
        >
          {{ title }}
        </div>
        <div
          :class="[
            'leading-5 text-md',
            { 'text-white': isSecondary },
            toastClass,
          ]"
        >
          {{ message }}
        </div>
      </div>

      <div
        v-if="link?.title && (link?.to || link.fn)"
        class="flex flex-shrink-0 ml-3"
      >
        <button
          v-if="link.fn"
          type="button"
          :class="['text-md font-bold', { 'text-white': isSecondary }]"
          @click="link.fn"
        >
          {{ link.title }}
        </button>
        <nuxt-link
          v-else
          :to="link.to"
          :class="['text-md font-bold', { 'text-white': isSecondary }]"
        >
          {{ link.title }}
        </nuxt-link>
      </div>

      <div v-if="showCloseButton" class="flex flex-shrink-0 ml-3">
        <button
          class="inline-flex text-gray-300 transition duration-150 ease-in-out focus:outline-none focus:text-gray-400"
          type="button"
          @click.prevent="close"
        >
          <BaseIcon class="font-black" name="cancel" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.base-toast-item {
  transition:
    transform ease-out 0.5s,
    opacity ease-out 0.5s;
  transform: translateX(10px);
}
.base-toast-item.show {
  transform: translateX(0);
  @apply opacity-100;
}
</style>
