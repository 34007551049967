export default (clientHttp, apiVersion) => ({
  read(params) {
    return clientHttp.get(`${apiVersion}/user/informations`, { ...params })
  },

  update(params) {
    return clientHttp.put(`${apiVersion}/user/informations`, {
      ...params,
    })
  },
})
