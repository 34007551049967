type BreakpointSize = 'mobile' | 'tablet' | 'laptop' | 'desktop' | '2xl'

export const useBreakpoint = () => {
  // Default breackpoint size
  const { isDesktop: isDesktopDevice, isTablet: isTabletDevice } = useDevice()
  const device = isDesktopDevice
    ? 'desktop'
    : isTabletDevice
      ? 'tablet'
      : 'mobile'

  const breakpointSize = useState<BreakpointSize>('breakpoint-size', () => {
    return device
  })

  // Screen sizes
  const isSmallScreen = computed(() =>
    ['mobile', 'tablet'].includes(breakpointSize.value),
  )
  const isMediumScreen = computed(() =>
    ['tablet', 'laptop'].includes(breakpointSize.value),
  )
  const isSmallAndMediumScreen = computed(() =>
    ['mobile', 'tablet', 'laptop'].includes(breakpointSize.value),
  )
  const isLargeScreen = computed(() =>
    ['desktop'].includes(breakpointSize.value),
  )
  const isBigScreen = computed(() => breakpointSize.value === '2xl')

  // Devices
  const isMobile = computed(() => ['mobile'].includes(breakpointSize.value))
  const isTablet = computed(() => ['tablet'].includes(breakpointSize.value))
  const isDesktop = computed(() =>
    ['laptop', 'desktop', '2xl'].includes(breakpointSize.value),
  )
  const isDesktopOrTablet = computed(() => isTablet.value || isDesktop.value)

  // Trigger Window
  const windowSizeChanged = () => {
    const bodyElement = document.querySelector('body')

    if (bodyElement) {
      const value = window
        .getComputedStyle(bodyElement, ':after')
        .getPropertyValue('content')
        .replace(/"/g, '') as BreakpointSize

      breakpointSize.value = value
    }
  }

  return {
    isBigScreen,
    isDesktop,
    isDesktopOrTablet,
    isLargeScreen,
    isMediumScreen,
    isMobile,
    isSmallAndMediumScreen,
    isSmallScreen,
    isTablet,
    windowSizeChanged,
  }
}
