<script setup lang="ts">
import type { ModalTypeSummary } from '~/types/wishlist'

defineEmits<{
  step: [step: Extract<ModalTypeSummary, 'delete' | 'edit' | 'share'>]
}>()

const actions = computed<
  {
    icon: IconPath
    key: 'share' | 'edit' | 'delete'
    keyTrad: string
  }[]
>(() => {
  return [
    {
      icon: 'upload',
      key: 'share',
      keyTrad: 'wishlistMultiple.shareFriend',
    },
    {
      icon: 'editPencil',
      key: 'edit',
      keyTrad: 'wishlistMultiple.renameTheList',
    },
    {
      icon: 'trash',
      key: 'delete',
      keyTrad: 'wishlistMultiple.deleteList',
    },
  ]
})
</script>

<template>
  <div>
    <div class="mb-5 lg:mb-10 font-bold text-xl">
      {{ $t('wishlistMultiple.parameters') }}
    </div>

    <div class="lg:border lg:border-gray-200 lg:rounded">
      <div
        v-for="(action, i) in actions"
        :key="i"
        class="border-b border-gray-200 last:border-b-0"
      >
        <BaseButton
          class="!bg-transparent hover:text-primary-700 !px-0 !py-3 lg:!px-3 flex items-center justify-between w-full"
          color="light"
          font-weight="font-normal"
          has-icon
          :aria-label="$t(action.keyTrad)"
          @click="$emit('step', action.key)"
        >
          <span class="flex items-center">
            <BaseIcon :name="action.icon" />
            <span class="pl-3.5 lg:text-md">{{ $t(action.keyTrad) }}</span>
          </span>
          <BaseIcon name="navArrowRight" />
        </BaseButton>
      </div>
    </div>
  </div>
</template>
