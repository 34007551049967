<script setup lang="ts">
const config = useRuntimeConfig()
const isDevelop = config.public.nodeEnv === 'development'
const props = withDefaults(
  defineProps<{
    flagKey?: string
  }>(),
  {
    flagKey: '',
  },
)

const condition = useExperimentCondition(props.flagKey)
</script>

<template>
  <slot v-if="condition.isControl" name="control" />
  <slot v-else-if="condition.isTreatment" name="treatment" />

  <p v-else-if="isDevelop">Missing value for flagKey: {{ flagKey }}</p>
</template>
