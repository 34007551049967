export const useAuthType = () => {
  type AuthVM = {
    user: {
      algoliaAdminSearchKey: string
      algoliaB2b2cSearchKey: string
      isAdmin: boolean
      partnershipAgency: boolean
    }
  }

  const auth: {
    value: AuthVM | null
  } = useState('auth')
  const userIsAdmin = ref(auth.value?.user?.isAdmin || false)
  const userIsPartner = ref(auth.value?.user?.partnershipAgency || false)

  return {
    userIsAdmin,
    userIsPartner,
  }
}
