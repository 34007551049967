import { createQueryV3 } from '~/repository/config/createQueryV3'

const getHouse = ({
  apiVersion,
  clientHttp,
  fields,
  includes,
  name,
  params,
  token,
}) => {
  let query = createQueryV3({ fields, includes })

  if (token) {
    query = `${query}&private_token=${token}`
  }

  return clientHttp.get(`${apiVersion}/houses/${name}${query}`, {
    ...params,
  })
}

export default (clientHttp, apiVersion) => ({
  async getHouseBySlug({ name, token, fields, includes }) {
    const res = await getHouse({
      apiVersion,
      clientHttp,
      fields,
      includes,
      name,
      token,
    })

    return res
  },
})
