<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

import {
  lowercase,
  min8Characters,
  specialCharacterOrNumber,
  uppercase,
} from '~/helpers/BaseForm/passwordHelper'

const props = withDefaults(
  defineProps<{
    disableAutocomplete?: boolean
    disabled?: boolean
    hasBorder?: boolean
    label?: string
    labelHtml?: string
    modelValue?: string
    name: string
    placeholder?: string
    rules?: string | Record<string, unknown>
    wrapperClass?: string
  }>(),
  {
    disableAutocomplete: false,
    disabled: false,
    hasBorder: true,
    label: '',
    labelHtml: '',
    modelValue: '',
    placeholder: '',
    rules: '',
    wrapperClass: 'w-full mb-4',
  },
)
const emits = defineEmits<{
  blur: []
  'update:model-value': [string]
}>()

const rulesInput = ref(props.rules)
const focusable = ref(false)
const showPassword = ref(false)
const {
  value: inputValue,
  handleBlur,
  handleChange,
  errors,
} = useField<string>(props.name, rulesInput, {
  initialValue: props.modelValue,
})

const gdprRules = ref<
  {
    state: 'valid' | 'invalid' | 'failed' | null
    name: string
    regex: RegExp
  }[]
>([
  { state: null, name: 'min8', regex: min8Characters },
  { state: null, name: 'lowercase', regex: lowercase },
  { state: null, name: 'uppercase', regex: uppercase },
  {
    state: null,
    name: 'specialCharacterOrNumber',
    regex: specialCharacterOrNumber,
  },
])

watch(
  () => props.rules,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      rulesInput.value = newValue
    }
  },
)

const isError = computed(() => Boolean(errors.value.length))
const computedClass: ComputedRef<string[]> = computed(() => {
  const computeClass = ['base-input-password']

  if (!props.hasBorder) {
    computeClass.push('base-input-password--noBorder')
  }
  if (props.disabled) {
    computeClass.push('base-input-password--disabled')
  }
  if (isError.value && props.hasBorder) {
    computeClass.push('base-input-password--hasError-hasBorder')
  }
  if (!isError.value && props.hasBorder) {
    computeClass.push('base-input-password--hasntError-hasBorder')
  }
  if (focusable.value) {
    computeClass.push('base-input-password--focus')
  }

  return computeClass
})
const hasGdprRules = computed(() =>
  typeof props.rules === 'string'
    ? props.rules.includes('gdprPassword')
    : 'gdprPassword' in props.rules,
)
const type = computed(() =>
  props.disableAutocomplete ? 'text' : showPassword.value ? 'text' : 'password',
)

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement

  handleChange(event)
  emits('update:model-value', target.value)

  if (hasGdprRules.value) {
    setValid(target.value)
  }
}

const onBlur = () => {
  focusable.value = false
  handleBlur()
  emits('blur')

  if (hasGdprRules.value) {
    setValid(inputValue.value, true)
  }
}

const onFocus = () => {
  focusable.value = true
}

const togglePassword = () => {
  showPassword.value = !showPassword.value
}

const setValid = (value: string, onBlur = false) => {
  for (const rule of gdprRules.value) {
    rule.state = rule.regex.test(value)
      ? 'valid'
      : onBlur
        ? 'failed'
        : 'invalid'
  }
}
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <div
      :class="[
        'relative w-full',
        { 'base-input-password-security': !showPassword },
      ]"
    >
      <input
        :id="name"
        :ref="name"
        :class="computedClass"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        :value="inputValue"
        class="w-full"
        data-testid="base-input-password"
        v-bind="$attrs"
        @blur="onBlur"
        @change="handleChange"
        @focus="onFocus"
        @input="onInput"
      />

      <button
        :aria-label="$t('global.passwordShowHide')"
        class="base-input-password--icon"
        type="button"
        @click="togglePassword"
      >
        <BaseIcon :name="showPassword ? 'eyeEmpty' : 'eyeOff'" />
      </button>
    </div>

    <error-message
      v-slot="{ message }"
      as="span"
      :name="name"
      class="base-form--error"
    >
      {{ message !== 'gdprPasswordInvalid' ? message : '' }}
    </error-message>

    <div
      v-if="hasGdprRules"
      class="w-full mt-4 text-sm text-gray-500 pl-3.5 border-l border-gray-300"
    >
      <p class="m-0">
        {{ $t('global.passwordGDPRRules.title') }}
      </p>
      <ul>
        <li
          v-for="rule in gdprRules"
          :key="rule.name"
          :class="[
            'list-disc ml-4 input-password__bullet',
            {
              'base-input-password-error': rule.state === 'failed',
              'base-input-password-success': rule.state === 'valid',
            },
          ]"
        >
          {{ $t(`global.passwordGDPRRules.${rule.name}`) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
:root {
  --lc-form-input-password-error-color: #dc6869;
  --lc-form-input-password-success-color: #448084;
}
.base-input-password-error {
  color: var(--lc-form-input-password-error-color);
}
.base-input-password-success {
  color: var(--lc-form-input-password-success-color);
}
.base-input-password {
  height: 51px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @apply block bg-clip-padding w-full text-gray-500 font-normal text-base leading-normal py-1.5 pl-4 pr-12 bg-white focus:text-gray-500 focus:bg-white focus:border-primary-100 focus:shadow-focus focus:outline-none border rounded-sm;
}
.base-input-password--noBorder {
  @apply border-none;
}
.base-input-password--disabled {
  @apply bg-gray-200 text-gray-500 cursor-not-allowed;
}
.base-input-password--hasError-hasBorder {
  @apply border-error;
}
.base-input-password--hasntError-hasBorder {
  @apply border-gray-200;
}
.base-input-password[disabled='disabled'] {
  -webkit-text-fill-color: #aaaaaa;
}
.base-input-password--icon {
  @apply absolute flex items-center inset-y-0 right-4 bottom-0 outline-none text-gray-400 w-5 focus:outline-none;
}
.base-input-password-security input {
  -webkit-text-security: disc;
}
</style>
