<script setup lang="ts">
import type { Color } from './BaseForm/types'

defineOptions({
  inheritAttrs: false,
})

const attrs = useAttrs()
const emits = defineEmits<{
  click: [value: MouseEvent]
}>()
const props = withDefaults(
  defineProps<{
    block?: boolean
    blockFull?: boolean
    color?: Color
    typo?: 'sm' | 'md'
    disabled?: boolean
    fontWeight?: 'font-bold' | 'font-normal' | 'font-medium'
    hasIcon?: boolean
    loader?: boolean
    noPadding?: boolean
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    variant?: 'btn' | 'link' | 'outline'
  }>(),
  {
    block: false,
    blockFull: false,
    color: 'primary',
    typo: 'sm',
    disabled: false,
    fontWeight: 'font-bold',
    hasIcon: false,
    loader: false,
    noPadding: false,
    size: 'md',
    variant: 'btn',
  },
)

const btnColors: {
  [key: string]: {
    [key: string]: string
  }
} = {
  btn: {
    primary: 'lc-btn--primary',
    secondary: 'lc-btn--secondary',
    light: 'lc-btn--light',
    white: 'lc-btn--white',
    grey: 'lc-btn--grey',
    black: 'lc-btn--black',
    disabled: 'lc-btn--disabled',
  },
  link: {
    primary: 'lc-link--primary',
    'primary-light': 'lc-link--primary-light',
    'primary-dark': 'lc-link--primary-dark',
    secondary: 'lc-link--secondary',
    grey: 'lc-link--grey',
    black: 'lc-link--black',
    'secondary-black': 'lc-link--secondary-black',
    'black-underline': 'lc-link--black-underline',
    white: 'lc-link--white',
    disabled: 'lc-link--disabled',
  },
  outline: {
    primary: 'lc-outline--primary',
    secondary: 'lc-outline--secondary',
    grey: 'lc-outline--grey',
    disabled: 'lc-outline--disabled',
  },
}
const btnSizes: {
  [key: string]: string
} = {
  xs: 'lc-btn--xs',
  sm: 'lc-btn--sm',
  md: 'lc-btn--md',
  lg: 'lc-btn--lg',
  xl: 'lc-btn--xl',
}
const btnTypos: {
  [key: string]: string
} = {
  sm: 'lc-btn--typo-sm',
  md: 'lc-btn--typo-md',
}
const btnVariants: {
  [key: string]: string
} = {
  btn: 'lc-btn',
  link: 'lc-link',
  outline: 'lc-outline',
}

const computedClass = computed(() => {
  // Variants
  const color = props.disabled ? 'disabled' : (props.color as string)
  const size = props.variant === 'link' ? '' : (props.size as string)
  const blockClass: {
    [key: string]: string
  } = {
    btn: props.block ? 'lc-btn--block' : '',
    link: props.block ? 'lc-link--block' : '',
    outline: props.block ? 'lc-outline--block' : '',
  }
  const blockFullClass: {
    [key: string]: string
  } = {
    btn: props.blockFull ? 'lc-btn--block-full' : '',
    link: props.blockFull ? 'lc-link--block-full' : '',
    outline: props.blockFull ? 'lc-outline--block-full' : '',
  }
  const hasIconClass: {
    [key: string]: string
  } = {
    btn: props.hasIcon ? 'lc-btn--has-icon' : '',
    link: props.hasIcon ? 'lc-link--has-icon' : '',
    outline: props.hasIcon ? 'lc-outline--has-icon' : '',
  }
  const weightClass: {
    [key: string]: string
  } = {
    btn: `lc-btn--${props.fontWeight}`,
    link: `lc-link--${props.fontWeight}`,
    outline: `lc-outline--${props.fontWeight}`,
  }
  const paddingClass = props.noPadding ? 'lc-btn--no-padding' : ''

  return [
    blockClass[props.variant],
    blockFullClass[props.variant],
    hasIconClass[props.variant],
    paddingClass,
    btnColors[props.variant][color],
    btnSizes[size],
    btnTypos[props.typo],
    btnVariants[props.variant],
    weightClass[props.variant],
  ]
})

const components = reactive({
  NuxtLink: {
    componentName: shallowRef(resolveComponent('NuxtLink')),
  },
  button: {
    componentName: shallowRef(h('button')),
  },
})
const type = computed(() =>
  typeof attrs.type === 'string' && ['button', 'submit'].includes(attrs.type)
    ? 'button'
    : 'NuxtLink',
)

const callback = (e: MouseEvent) => {
  emits('click', e)
}
</script>

<template>
  <component
    :is="components[type].componentName"
    :class="computedClass"
    :disabled="disabled"
    v-bind="$attrs"
    @click="callback"
  >
    <span v-if="loader" class="lc-btn-loader">
      <i class="lc-btn-loader__spin" />
    </span>
    <slot />
  </component>
</template>

<style>
/* Sizes */
.lc-btn--xs {
  @apply p-0;
}
.lc-btn--sm {
  @apply py-2.5 px-6;
}
.lc-btn--md {
  @apply py-3.5 px-6;
}
.lc-btn--lg {
  @apply py-4 px-8;
}
.lc-btn--xl {
  @apply py-4 px-12;
}

/* Types */
.lc-btn {
  user-select: none;
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  @apply rounded inline-block text-center cursor-pointer border border-solid border-transparent leading-tight hover:no-underline focus:outline-none focus:border-primary-200 font-bold;
}
.lc-link {
  user-select: none;
  transition:
    color 0.2s ease-in-out,
    text-decoration 0.2s ease-in-out;
  @apply bg-transparent p-0 inline-block border-none outline-none hover:no-underline focus:outline-none cursor-pointer;
}
.lc-outline {
  @apply text-base rounded inline-block text-center cursor-pointer align-middle border border-solid leading-tight hover:no-underline focus:outline-none focus:border-primary-200;
}

/* Typos */
.lc-btn.lc-btn--typo-sm,
.lc-outline .lc-btn--typo-sm {
  @apply text-base;
}
.lc-btn.lc-btn--typo-md {
  @apply text-base;
}

/* HasIcon */
.lc-btn.lc-btn--has-icon,
.lc-link.lc-link--has-icon,
.lc-outline.lc-outline--has-icon {
  @apply flex items-center text-center;
}

.lc-btn.lc-btn--has-icon span {
  @apply align-middle;
}

.lc-link.lc-link--has-icon {
  @apply hover:no-underline;
}

/* Block */
.lc-btn.lc-btn--block,
.lc-link.lc-link--block,
.lc-outline.lc-outline--block {
  @apply block;
}

/* Block */
.lc-btn.lc-btn--block-full,
.lc-link.lc-link--block-full,
.lc-outline.lc-outline--block-full {
  @apply block w-full;
}

/* Weight */
.lc-btn--font-bold,
.lc-link--font-bold,
.lc-outline--font-bold {
  @apply font-bold;
}
.lc-btn--font-normal,
.lc-link--font-normal,
.lc-outline--font-normal {
  @apply font-normal;
}
.lc-btn--font-medium,
.lc-link--font-medium,
.lc-outline--font-medium {
  @apply font-medium;
}

/* Padding */
.lc-btn.lc-btn--no-padding {
  @apply p-0;
}

/* Btn Colors */
.lc-btn--primary {
  @apply bg-primary-600 hover:bg-primary-700 text-white hover:text-white;
}
.lc-btn--primary:focus {
  box-shadow: 0 0 0 0.2rem #dbbc8f4d;
}
.lc-btn--secondary {
  @apply bg-secondary-400 hover:bg-secondary-500 text-white hover:text-white;
}
.lc-btn--secondary:focus {
  box-shadow: 0 0 0 0.2rem #1952524d;
}
.lc-btn--light {
  @apply bg-white text-gray-700 hover:bg-gray-200;
}
.lc-btn--white {
  @apply text-white hover:text-white;
}
.lc-btn--grey {
  @apply text-gray-500 focus:text-gray-500 md:hover:text-gray-700;
}
.lc-btn--black {
  @apply text-gray-700 focus:text-gray-700 md:hover:text-gray-700;
}
.lc-btn--disabled {
  @apply pointer-events-none bg-gray-300 hover:bg-gray-300 text-white;
}

/* Link Colors */
.lc-link--primary {
  @apply text-primary-800 focus:text-primary-700 md:hover:text-primary-700;
}
.lc-link--primary-light {
  @apply text-primary-500 focus:text-primary-500 md:hover:text-primary-600;
}
.lc-link--primary-dark {
  @apply text-primary-900 focus:text-primary-800 md:hover:text-primary-800;
}
.lc-link--secondary {
  @apply text-secondary-500 focus:text-secondary-500 md:hover:text-secondary-500;
}
.lc-link--secondary:focus {
  box-shadow: 0 0 0 0.2rem #1952524d;
}
.lc-link--grey {
  @apply text-gray-600 focus:text-gray-600 hover:text-gray-700;
}
.lc-link--black {
  @apply text-gray-700 focus:text-gray-700 hover:text-gray-700 md:hover:text-gray-300;
}
.lc-link--secondary-black {
  @apply text-gray-700 focus:text-gray-700 hover:text-gray-700 hover:no-underline;
}
.lc-link--black-underline {
  @apply text-gray-700 focus:text-gray-700 hover:text-gray-700 hover:underline;
}
.lc-link--white {
  @apply text-white focus:text-white focus:underline hover:text-white hover:underline;
}
.lc-link--disabled {
  @apply pointer-events-none text-gray-300 md:hover:text-gray-300;
}

/* Outline Colors */
.lc-outline--primary {
  @apply bg-white font-medium text-primary-500 border-primary-500 hover:bg-gray-100 hover:text-primary-500;
}
.lc-outline--secondary {
  @apply bg-white font-medium text-secondary-400 border-secondary-400 focus:bg-white focus:font-medium focus:text-secondary-400 focus:border-secondary-400 hover:bg-secondary-100 hover:text-secondary-400;
}
.lc-outline--secondary:focus {
  box-shadow: 0 0 0 0.2rem #1952524d;
}
.lc-outline--grey {
  @apply bg-white font-medium text-gray-700 border-gray-300 focus:bg-white focus:font-medium focus:text-gray-700 focus:border-gray-300 hover:bg-gray-100 hover:text-gray-700;
}
.lc-outline--disabled {
  @apply pointer-events-none bg-white font-medium border border-gray-400 text-gray-300 md:hover:text-gray-300;
}

/* Btn Loader */
.lc-btn-loader {
  @apply w-5 h-5 inline-block relative align-middle mr-4;
}
.lc-btn-loader__spin {
  transform: rotate(0deg);
  border: 0.1875rem solid #dbbc8f4d;
  border-top: 0.1875rem solid #dbbc8f;
  animation: spinBaseButton 1s linear infinite;
  @apply absolute inset-1/2 w-5 h-5 rounded-full;
}
.lc-btn--primary .lc-btn-loader__spin {
  border: 0.1875rem solid #1952514d;
  border-top: 0.1875rem solid #195251;
}
@keyframes spinBaseButton {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
