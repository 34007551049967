<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'
import debounce from 'lodash.debounce'

import type { Rules } from '../types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    boundTo?: string
    decimal?: boolean
    disabled?: boolean
    disablePrefixInput?: boolean
    hasBorder?: boolean
    icon?: string
    iconWrapperClass?: string
    infoText?: string
    inputHtml?: string
    label?: string
    labelHtml?: string
    modelValue?: string | number
    name: string
    negativeNumber?: boolean
    placeholder?: string
    prefixInput?: string
    rules?: Rules
    showErrorText?: boolean
    suffixInput?: string
    wrapperClass?: string
  }>(),
  {
    boundTo: '',
    decimal: false,
    disabled: false,
    disablePrefixInput: false,
    hasBorder: true,
    icon: '',
    iconWrapperClass: '',
    infoText: '',
    inputHtml: '',
    label: '',
    labelHtml: '',
    modelValue: '',
    negativeNumber: false,
    placeholder: '',
    prefixInput: '',
    rules: '',
    showErrorText: true,
    suffixInput: '',
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  'update:model-value': [string]
  focus: []
  blur: []
  'trigger-interdependency': [string, string]
}>()
const rulesInput = ref(props.rules)
const focusable = ref(false)
const {
  value: inputValue,
  handleBlur,
  handleChange,
  errors,
} = useField<string | number>(props.name, rulesInput, {
  initialValue: props.modelValue,
})

const isError = computed(() => Boolean(errors.value.length))

const onInput = (event: Event): void => {
  const target = event.target as HTMLInputElement

  if (props.negativeNumber) {
    target.value = String(-Math.abs(Number(target.value)))
  }

  if (props.boundTo) {
    triggerInterdependency()
  }
  emits('update:model-value', target.value)
  handleChange(event)
}

const onBlur = (): void => {
  focusable.value = false
  handleBlur()
  emits('blur')
}

const onFocus = (): void => {
  focusable.value = true
  emits('focus')
}

const isNumberKey = (
  event: Event & { which: number; keyCode: number; target: HTMLInputElement },
) => {
  const ASCIICode = event.which ? event.which : event.keyCode

  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    if (!props.decimal) {
      return false
    } else if (ASCIICode === 44) {
      return true
    } else {
      return false
    }
  } else if (props.decimal) {
    return authorize2Decimal(event)
  }

  return true
}

const authorize2Decimal = (event: Event & { target: HTMLInputElement }) => {
  if (
    event.target.value.includes('.') &&
    event.target.value.split('.')[1].length === 2
  ) {
    return false
  }

  return true
}

const triggerInterdependency = debounce(() => {
  emits('trigger-interdependency', props.name, props.boundTo)
}, 100)

const computedClass: ComputedRef<string[]> = computed(() => {
  const computeClass = ['base-input']

  if (!props.hasBorder) {
    computeClass.push('base-input--noBorder')
  }
  if (props.disabled) {
    computeClass.push('base-input--disabled')
  }
  if (isError.value && props.hasBorder) {
    computeClass.push('base-input--hasError-hasBorder')
  }
  if (!isError.value && props.hasBorder) {
    computeClass.push('base-input--hasntError-hasBorder')
  }
  if (focusable.value) {
    computeClass.push('base-input--focus')
  }

  return computeClass
})

watch(
  () => props.rules,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      rulesInput.value = newValue
    }
  },
)

defineExpose({
  inputValue,
})
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <div :class="computedClass" data-testid="base-input-container">
      <div
        v-if="prefixInput"
        :class="['base-input--prefix', { 'bg-gray-200': disablePrefixInput }]"
      >
        {{ prefixInput }}
      </div>
      <input
        :id="name"
        :ref="name"
        :disabled="disabled"
        :name="name"
        type="text"
        :onkeypress="$attrs.type === 'number' ? isNumberKey : null"
        :placeholder="placeholder"
        :value="inputValue"
        v-bind="$attrs"
        @blur="onBlur"
        @change="handleChange"
        @focus="onFocus"
        @input="onInput"
      />
      <div v-if="suffixInput" class="base-input--suffix">
        {{ suffixInput }}
      </div>
    </div>

    <BaseFormComponentsBaseStringToHtml
      v-if="inputHtml"
      :str-html="inputHtml"
    />

    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="infoText" />

    <error-message
      v-if="showErrorText"
      :name="name"
      as="span"
      class="base-form--error"
    />
  </div>
</template>

<style>
.base-input {
  height: 51px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @apply flex items-center bg-clip-padding w-full text-gray-500 font-normal text-base leading-normal bg-white focus:text-gray-500 focus:bg-white focus:border-primary-100 focus:shadow-focus focus:outline-none border rounded-sm;
}
.base-input input {
  @apply w-full py-1.5 px-4 h-full focus:outline-none text-gray-700;
}

.base-input.base-input--focus {
  @apply shadow-focus;
}

.base-input--prefix {
  @apply pr-2 flex flex-none h-full pl-4 items-center;
}

.base-input--suffix {
  @apply pl-2 flex flex-none h-full pr-4 items-center;
}

.base-input--noBorder {
  @apply border-none;
}
.base-input--disabled,
.base-input--disabled input {
  @apply bg-gray-200 text-gray-500 cursor-not-allowed;
}
.base-input--hasError-hasBorder {
  @apply border-error;
}
.base-input--hasntError-hasBorder {
  @apply border-gray-200;
}
.base-input[disabled='disabled'] {
  -webkit-text-fill-color: #eaeaea;
}

.base-input input[type='number']::-webkit-inner-spin-button,
.base-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
