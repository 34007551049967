<script setup lang="ts">
const { housesWishlistActiveInHeader } = useMultipleWishlist()
const { setSummaryOpen, summaryOpen } = useMultipleWishlistSummary()
const { headerTheme } = useHeaderNavigation()

const colorIcon = computed(() =>
  headerTheme.value === 'transparent' ? 'white' : 'black',
)

const svgStrokeClass = computed(() => {
  if (housesWishlistActiveInHeader.value.exist) {
    return 'duration-1000 fill-primary-800 stroke-primary-800'
  }

  return `duration-200 fill-transparent ${headerTheme.value === 'transparent' ? 'stroke-white' : 'stroke-gray-700'}`
})
</script>

<template>
  <BaseButton
    id="header-wishlist"
    :aria-label="$t('header.wishlist')"
    :color="colorIcon"
    class="relative !border-0"
    has-icon
    no-padding
    role="menu"
    type="button"
    @click="setSummaryOpen(!summaryOpen)"
  >
    <BaseIcon
      name="heart"
      :class="['transition ease-in-out', svgStrokeClass]"
      :stroke-width="1"
    />
    <span
      v-if="housesWishlistActiveInHeader.exist"
      class="absolute top-0 left-0 right-0 bottom-0 text-[7.5px] text-white flex items-center justify-center"
    >
      {{ housesWishlistActiveInHeader.number }}
    </span>
  </BaseButton>
</template>
