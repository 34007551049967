<script setup lang="ts">
import sanitizeHtml from 'sanitize-html'

const props = withDefaults(
  defineProps<{
    value?: string
    inputType: 'textarea' | 'wysiwyg'
  }>(),
  {
    value: '',
  },
)

const { t } = useI18n()

const isTextarea = Boolean(props.inputType === 'textarea')
const valueFormatted = computed(() => {
  const regexLink = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/g

  const allowedTags = [
    'a',
    'br',
    'col',
    'colgroup',
    'div',
    'li',
    'ol',
    'p',
    'span',
    'strong',
    'table',
    'tbody',
    'td',
    'tr',
    'ul',
  ]

  const imgTags = props.value?.match(/<img [^>]*src="[^"]*"[^>]*>/gm) || []
  const valid = imgTags?.every((x: string) =>
    x.includes('https://cdn.lecollectionist.com/'),
  )
  if (valid) {
    allowedTags.push('img')
  }

  return sanitizeHtml(
    props.value?.replace(
      regexLink,
      `<a href="$1" target="_blank" class="lc-link--primary lc-link lc-link--font-bold">${t(
        'global.link',
      )}<a>`,
    ) || '/',
    {
      allowedTags,
      allowedAttributes: {
        a: ['href', 'class'],
        colgroup: ['style'],
        div: ['class'],
        img: ['src', 'alt', 'width', 'height', 'class'],
        li: ['class'],
        ol: ['class'],
        p: ['class'],
        span: ['class'],
        strong: ['class'],
        table: ['class', 'style'],
        ul: ['class'],
      },
    },
  )
})
</script>

<template>
  <div
    data-testid="base-field-read-mode-textedit"
    :class="[
      'text-edit-readmode text-gray-500',
      { 'whitespace-pre-line': isTextarea },
    ]"
    v-html="valueFormatted"
  />
</template>

<style>
.text-edit-readmode > ul > li,
.text-edit-readmode > ol > li {
  @apply mb-2;
}

.text-edit-readmode > ul,
.text-edit-readmode > ol {
  @apply mb-1 list-inside;
}

.text-edit-readmode > ul {
  @apply list-disc;
}

.text-edit-readmode > ol {
  @apply list-decimal;
}

.text-edit-readmode > p {
  @apply mb-1;
}

.text-edit-readmode > h1 {
  @apply text-6xl mb-4;
}

.text-edit-readmode > h2 {
  @apply text-5xl mb-4;
}

.text-edit-readmode > h3 {
  @apply text-4xl mb-4;
}

.text-edit-readmode > h4 {
  @apply text-3xl mb-2;
}

.text-edit-readmode > h5 {
  @apply text-2xl mb-2;
}

.text-edit-readmode > h6 {
  @apply text-xl mb-2;
}
.text-edit-readmode table td {
  @apply border p-1;
}
</style>
