<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name?: string
    modelValue?: boolean
  }>(),
  {
    name: '',
    modelValue: false,
  },
)
const emits = defineEmits<{
  'update:model-value': [boolean]
  'previous-step': []
}>()

const toggleNav = () => {
  emits('update:model-value', !props.modelValue)
}
const previousStep = () => {
  emits('previous-step')
}
</script>

<template>
  <div class="flex items-center justify-between w-full bg-white">
    <base-button
      :aria-label="$t('global.back')"
      color="black"
      data-testid="the-header-slider-menu--previous-btn"
      variant="link"
      @click="previousStep"
    >
      <BaseIcon name="navArrowLeft" />
    </base-button>
    <span class="text-4xl leading-none font-monospace">{{ name }}</span>
    <the-header-mobile-burger
      :model-value="modelValue"
      data-testid="the-header-slider-menu--burger-btn"
      @update:model-value="toggleNav"
    />
  </div>
</template>
