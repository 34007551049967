<script setup lang="ts">
const { newIllustrationPaths, paths } = useIconPaths()

const props = withDefaults(
  defineProps<{
    color?: string
    filled?: boolean
    name: IconPath
    size?: number
  }>(),
  {
    color: '',
    size: 1.5,
  },
)

const path = computed(() => {
  if (paths[props.name]) {
    return paths[props.name]
  }

  return ''
})
const strokeWidth = computed(() => {
  if (Object.keys(newIllustrationPaths).includes(props.name)) {
    return 0.8
  }

  return 24 / (props.size * 16)
})

const viewbox = computed(() => {
  if (Object.keys(newIllustrationPaths).includes(props.name)) {
    return '0 0 32 32'
  }

  return '0 0 24 24'
})
</script>

<template>
  <svg
    v-if="Array.isArray(path)"
    :class="color"
    :style="{
      width: `${size}rem`,
      height: `${size}rem`,
    }"
    :stroke-width="strokeWidth"
    :fill="filled ? 'currentColor' : 'none'"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke="currentColor"
    :viewBox="viewbox"
  >
    <path v-for="p in path" :key="p" :d="p" />
  </svg>
  <svg
    v-else
    :class="color"
    :style="{
      width: `${size}rem`,
      height: `${size}rem`,
    }"
    :stroke-width="strokeWidth"
    :fill="filled ? 'currentColor' : 'none'"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke="currentColor"
    :viewBox="viewbox"
  >
    <path :d="path" />
  </svg>
</template>
