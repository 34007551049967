export default (clientHttp, apiVersion) => ({
  create(params) {
    return clientHttp.post(`${apiVersion}/user/companies`, {
      ...params,
    })
  },

  update(id, params) {
    return clientHttp.put(`${apiVersion}/user/companies/${id}`, {
      ...params,
    })
  },

  read() {
    return clientHttp.get(`${apiVersion}/user/companies`)
  },

  delete(companyId) {
    return clientHttp.delete(`${apiVersion}/user/companies/${companyId}`)
  },
})
