<script setup lang="ts">
import type { CSSProperties } from 'vue'
import { useLockBody } from '~/composables/useLockBody'

const { isDesktop } = useDevice()

const emits = defineEmits<{
  'close-modal': []
  'update:model-value': [boolean]
  'all-read': [boolean]
}>()
const props = withDefaults(
  defineProps<{
    activeEscMode?: boolean
    compulsoryReading?: boolean
    disableCloseModal?: boolean
    fullHeight?: boolean
    htmlContent?: string
    modalTitle?: string
    modalStyle?: {
      modal?: Record<string, unknown>
      header?: Record<string, unknown>
      content?: Record<string, unknown>
      footer?: Record<string, unknown>
    }
    multipleModals?: boolean
    noFooter?: boolean
    noHeader?: boolean
    modelValue?: boolean
  }>(),
  {
    activeEscMode: false,
    compulsoryReading: false,
    disableCloseModal: false,
    fullHeight: false,
    htmlContent: '',
    modalTitle: '',
    modalStyle: () => ({
      modal: {},
      header: {},
      content: {},
      footer: {},
    }),
    multipleModals: false,
    noFooter: false,
    noHeader: false,
    modelValue: false,
  },
)

const lcModalContent = ref<HTMLDivElement>()
const allRead = ref(false)
const style = ref({
  content: {
    height: 'auto',
  },
  footer: {
    textAlign: 'center' as CSSProperties['text-align'],
  },
  header: {},
  modal: {},
})

const copyOptions = <T = Record<string, unknown>,>(
  src: T,
  dst: Record<keyof T, Record<string, unknown>>,
) => {
  for (const key in src) {
    Object.assign(dst[key], src[key])
  }
}
copyOptions(props.modalStyle, style.value)

watch(
  () => props.multipleModals,
  (newVal) => {
    useLockBody(newVal)
  },
)

watch(
  () => props.modelValue,
  (newVal) => {
    updateOverflow(newVal)
  },
)

watch(
  () => props.modalStyle,
  () => {
    copyOptions(props.modalStyle, style.value)
  },
)

const clickOnShadow = () => {
  if (!props.disableCloseModal) {
    closeModal()
  }
}

const closeModal = () => {
  emits('close-modal')
  emits('update:model-value', false)
}

const updateOverflow = (newVal = false) => {
  if (!props.multipleModals) {
    useLockBody(newVal)
  }
}
const scroll = (e: Event) => {
  if (props.compulsoryReading) {
    const target = e.target as HTMLDivElement
    if (!target) return
    const offsetTop = target.scrollHeight - target.offsetHeight
    const scrollTop = Math.round(target.scrollTop)

    if (Math.abs(offsetTop - scrollTop) < 10) {
      allRead.value = true
      emits('all-read', true)
    }
  }
}

const scrollTo = () => {
  const element = lcModalContent.value
  if (!element) return
  const height = element.scrollHeight
  element.scrollTo({
    top: height,
    left: 0,
    behavior: 'smooth',
  })
}

const escFunction = (e: KeyboardEvent) => {
  if (e.key === 'Escape') closeModal()
}

onMounted(() => {
  if (isDesktop && props.activeEscMode) {
    document.addEventListener('keyup', escFunction, false)
  }

  updateOverflow()
})

onBeforeUnmount(() => {
  if (isDesktop && props.activeEscMode) {
    document.removeEventListener('keyup', escFunction, false)
  }

  useLockBody(false)
})
</script>

<template>
  <transition name="fade">
    <div v-if="modelValue" class="lc-modal" role="dialog" aria-modal="true">
      <div class="lc-modal__shadow" @click="clickOnShadow" />
      <div
        :class="[
          'lc-modal__wrap',
          { 'lc-modal__wrap--full-height': fullHeight },
        ]"
        :style="style.modal"
      >
        <div
          :class="[
            'lc-modal__container',
            { 'lc-modal__container--full-height': fullHeight },
          ]"
        >
          <base-close-button
            v-if="!disableCloseModal"
            :aria-label="$t('global.close')"
            class="lc-modal__close-button"
            data-cy="close-modal"
            @click="closeModal"
          />
          <header
            v-if="!noHeader"
            class="lc-modal__container--header"
            :style="style.header"
          >
            <slot name="header">
              <h2 class="text-4xl">
                {{ modalTitle }}
              </h2>
            </slot>
          </header>
          <div
            ref="lcModalContent"
            class="lc-modal__content"
            :style="style.content"
            @scroll="scroll"
          >
            <slot name="content">
              <div class="content-html" v-html="htmlContent" />
            </slot>
          </div>
          <footer
            v-if="!noFooter"
            :class="[
              'lc-modal__container--footer',
              { '!fixed bg-white p-2 left-0 right-0 bottom-0': fullHeight },
            ]"
            :style="style.footer"
          >
            <slot name="footer">
              <button :disabled="!allRead" type="button">
                {{ $t('modal.primaryButton') }}
              </button>
            </slot>
            <button
              v-if="compulsoryReading"
              :aria-label="$t('global.scrollTo')"
              class="lc-modal__button_scroll"
              type="button"
              @click="scrollTo"
            >
              <i class="scroll_to-line scroll_to-line--left" />
              <i class="scroll_to-line scroll_to-line--right" />
            </button>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
.lc-modal {
  z-index: 99999;
  @apply fixed top-0 left-0 right-0 h-full flex mx-auto my-0 items-center justify-center;
}

.lc-modal__shadow {
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  @apply absolute top-0 left-0 right-0 h-full;
}

.lc-modal__wrap {
  max-width: 500px;
  z-index: 99999;
  @apply relative flex mx-auto my-0 items-center w-full p-4;
}

.lc-modal__wrap--full-height {
  @apply h-full p-0;
}

.lc-modal__container {
  @apply w-full relative bg-white;
}

.lc-modal__container--header {
  @apply relative text-center pointer-events-none py-4 px-10;
}

.lc-modal__container--header h2 {
  @apply m-0;
}

.lc-modal__container--footer {
  @apply relative py-4 px-6;
}

.lc-modal__container--full-height {
  @apply h-full;
}

.lc-modal__container--full-height .lc-modal__container--footer,
.lc-modal__container--full-height .lc-modal__container--header {
  box-shadow: 0 0 10px 0px rgba(32, 32, 32, 0.15);
}

.lc-modal__close-button.close-button {
  @apply absolute right-0 top-2 shadow-none outline-none;
}

.lc-modal__content {
  @apply overflow-y-auto p-6;
  height: calc(100% - 65px);
}

.lc-modal__content .content-html h1 {
  @apply text-6xl text-center;
}

.lc-modal__content .content-html h2 {
  @apply text-4xl mx-0 mt-8 mb-6;
}

.lc-modal__content .content-html h3 {
  @apply text-4xl mx-0 mt-8 mb-6;
}

.lc-modal__content .content-html h4 {
  @apply text-2xl mx-0 mt-8 mb-6;
}

.lc-modal__content .content-html ul {
  @apply pl-6 list-disc;
}

.lc-modal__content .content-html ul li {
  @apply mb-4;
}

.lc-modal__button_scroll {
  right: 25px;
  height: 40px;
  width: 40px;
  background: transparent;
  border-radius: 0.125rem;
  transform: translateY(-50%);
  box-shadow: none;
  outline: none;
  transition: all ease 0.5s;
  @apply border border-gray-500 absolute top-1/2;
}

.lc-modal__button_scroll:hover {
  @apply border-secondary-500;
}

.lc-modal__button_scroll:hover .scroll_to-line {
  @apply border-secondary-500;
}

.lc-modal__button_scroll .scroll_to-line {
  height: 10px;
  width: 2px;
  transform: translateY(-50%);
  transition: all ease 0.5s;
  @apply bg-gray-500 absolute top-1/2 left-1/2;
}

.lc-modal__button_scroll .scroll_to-line--left {
  transform: translate(-50%, -50%) rotate(-45deg);
  left: calc(45% - 1px);
}

.lc-modal__button_scroll .scroll_to-line--right {
  transform: translate(-50%, -50%) rotate(45deg);
  left: calc(55% + 1px);
}
</style>
