import { experimentTranslations } from '~/utils/experimentTranslations'

import type { Locale } from 'lc-services/types'
import type { ExperimentTranslation } from '~/utils/experimentTranslations'
import type { UserVariant, VariantKey } from '~/types/amplitude/types'

const findTopParentKey = (
  obj: ExperimentTranslation,
  targetKey: string,
): string | null => {
  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      typeof obj[key] === 'object' &&
      obj[key] !== null
    ) {
      if (Object.prototype.hasOwnProperty.call(obj[key], targetKey)) return key

      const result = findTopParentKey(obj[key] as any, targetKey)
      if (result) return key
    }
  }

  return null
}

export const useExperimentTranslations = (key: string) => {
  const { $i18n } = useNuxtApp()
  const locale = $i18n.locale as Ref<Locale>
  const userVariant = useState<UserVariant>('user-variant')

  if (!userVariant.value && process.env.NODE_ENV === 'development') {
    console.error('No user variant found', key)
  }

  const flagKey = findTopParentKey(
    experimentTranslations[locale.value],
    key,
  ) as string
  const variant = computed<VariantKey>(() => userVariant.value[flagKey])

  return computed(() => {
    if (
      variant.value &&
      flagKey &&
      experimentTranslations[locale.value][flagKey][variant.value][key]
    ) {
      return experimentTranslations[locale.value][flagKey][variant.value][key]
    }

    if (process.env.NODE_ENV === 'development') {
      console.error(
        `No translation found for flag: ${flagKey} and variant: ${variant.value} and key: ${key}`,
      )
    }

    return ''
  })
}
