import ActionCableVue from 'actioncable-vue'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const actionCableVueOptions = {
    debug: true,
    debugLevel: 'all',
    connectionUrl: config.public.actionCableWebsocketUrl,
    connectImmediately: false,
  }

  nuxtApp.vueApp.use(ActionCableVue, actionCableVueOptions)

  return {
    provide: {
      cable: ActionCableVue.install(nuxtApp.vueApp, actionCableVueOptions),
    },
  }
})
