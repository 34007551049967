// API V1
import auth from '~/repository/services/v1/auth/index'
import callbackRequests from '~/repository/services/v1/callbackRequests'
import clientContracts from '~/repository/services/v1/clientContracts'
import clientGeneralRentalCondition from '~/repository/services/v1/clientGeneralRentalCondition'
import contacts from '~/repository/services/v1/contacts'
import destinations from '~/repository/services/v1/destinations/index'
import experiences from '~/repository/services/v1/experiences'
import housePeriods from '~/repository/services/v1/housePeriods'
import houses from '~/repository/services/v1/houses/index'
import housesBookings from '~/repository/services/v1/houses/bookings'
import housesExperiences from '~/repository/services/v1/houses/experiences'
import housesPeriods from '~/repository/services/v1/houses/periods'
import housesPhotos from '~/repository/services/v1/houses/photos'
import housesSectionsTags from '~/repository/services/v1/houses/sections/tags'
import housesStarrings from '~/repository/services/v1/houses/starrings'
import newsletterEmails from '~/repository/services/v1/newsletterEmails'
import ownerContracts from '~/repository/services/v1/ownerContracts'
import ownerGeneralRentalCondition from '~/repository/services/v1/ownerGeneralRentalCondition'
import paymentTransactions from '~/repository/services/v1/paymentTransactions'
import requests from '~/repository/services/v1/requests'
import termsOfService from '~/repository/services/v1/termsOfService'
import userClientGeneralRentalCondition from '~/repository/services/v1/user/clientGeneralRentalCondition'
import userCompanies from '~/repository/services/v1/user/companies'
import userDocuments from '~/repository/services/v1/user/documents'
import userHouses from '~/repository/services/v1/user/houses/index'
import userHousesCalendarExports from '~/repository/services/v1/user/houses/calendarExports'
import userHousesCalendarImports from '~/repository/services/v1/user/houses/calendarImports'
import userHousesContractInformations from '~/repository/services/v1/user/houses/contractInformations'
import userHousesPeriods from '~/repository/services/v1/user/houses/periods'
import userInformations from '~/repository/services/v1/user/informations'
import userOwnerContracts from '~/repository/services/v1/user/ownerContracts'
import userOwnerGeneralRentalCondition from '~/repository/services/v1/user/ownerGeneralRentalCondition'
import userStays from '~/repository/services/v1/user/stays'

export default {
  v1: {
    auth,
    callbackRequests,
    clientContracts,
    clientGeneralRentalCondition,
    contacts,
    destinations,
    experiences,
    housePeriods,
    houses,
    housesBookings,
    housesExperiences,
    housesPeriods,
    housesPhotos,
    housesSectionsTags,
    housesStarrings,
    newsletterEmails,
    ownerContracts,
    ownerGeneralRentalCondition,
    paymentTransactions,
    requests,
    termsOfService,
    userClientGeneralRentalCondition,
    userCompanies,
    userDocuments,
    userHouses,
    userHousesCalendarExports,
    userHousesCalendarImports,
    userHousesContractInformations,
    userHousesPeriods,
    userInformations,
    userOwnerContracts,
    userOwnerGeneralRentalCondition,
    userStays,
  },
}
