<script setup lang="ts">
const config = useRuntimeConfig()
const localePath = useLocalePath()
const { trackEvent } = useTracking()

const props = defineProps<{
  wishlistToken: string
}>()

const url = `${config.public.baseUrl}${localePath({
  name: 'wishlist-token-shared',
  params: { token: props.wishlistToken },
})}`

const tracking = (label: string) => {
  trackEvent({
    category: 'wishlist',
    event: 'wishlist_shared',
    label,
    wishlist_id: props.wishlistToken,
  })
}
</script>

<template>
  <div>
    <div class="mb-5 tablet:mb-10 font-bold text-xl">
      {{ $t('wishlistMultiple.shareList') }}
    </div>
    <BaseShare :url-custom="url" @tracking="tracking" />
  </div>
</template>
