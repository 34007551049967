<template>
  <ContactForm>
    <template #title>
      <p class="font-monospace leading-8 text-4xl mb-6">
        {{ $t('contact.title') }}
      </p>
      <p class="mb-6">
        {{ $t('contact.description') }}
      </p>
    </template>
  </ContactForm>
</template>
