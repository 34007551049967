const clearHeadersAndCookies = ({ $api, $cookies, $lcRepositories }) => {
  const { clearHeaders } = useApiHeaders({
    $api,
    $lcRepositories,
  })

  $cookies.set('access-token', '')
  $cookies.set('client', '')
  $cookies.set('uid', '')
  $cookies.set('search_admin_token', '')
  $cookies.set('search_b2b2c_token', '')

  clearHeaders()
}

export const moduleAuth = ({
  $api,
  $cookies,
  $lcRepositories,
  router,
  localePath,
}) => {
  return {
    namespaced: true,
    state() {
      return { loggedIn: false, user: {} }
    },
    mutations: {
      SET_USER(state, payload) {
        state.user = payload
      },
      SET_LOGGED_IN(state, payload) {
        state.loggedIn = payload
      },
    },
    actions: {
      async logout({ commit }, payload = { redirect: false, returnTo: null }) {
        try {
          await $api.v1.auth.signOut()

          useState('auth').value = {
            user: {},
            authenticated: false,
          }

          commit('SET_USER', {})
          commit('SET_LOGGED_IN', false)

          clearHeadersAndCookies({ $api, $cookies, $lcRepositories })

          const { clearWishlists } = useMultipleWishlist()
          clearWishlists()

          if (payload.redirect) {
            const query = payload?.returnTo
              ? { return_to: payload.returnTo }
              : null

            router.push({ path: localePath({ name: 'login', query }) })
          }
        } catch (err) {
          return Promise.reject(err)
        }
      },
    },
  }
}
