export default (clientHttp, apiVersion) => ({
  read(id, tag, params) {
    const availableTags = [
      'rooms',
      'location',
      'essentials',
      'amenities',
      'outdoor_and_recreation',
    ]

    if (!availableTags.includes(tag)) {
      throw `Section ${tag} unknow`
    }

    return clientHttp.get(`${apiVersion}/houses/${id}/sections/${tag}`, {
      ...params,
    })
  },
})
