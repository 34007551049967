const hasCookie = (cookie) => {
  try {
    return Object.values(cookie).some((d) => d.length > 0)
  } catch {
    return false
  }
}

export const modulePreOrder = ({ $httpCookies }) => {
  return {
    namespaced: true,
    state() {
      return {
        destinationId: '',
        endDate: '',
        externalIdentifier: '',
        nightCount: '',
        pid: '',
        price: '',
        requestId: '',
        startDate: '',
      }
    },
    getters: {
      isEmpty: (state) => {
        return Object.values(state).includes('')
      },
    },
    mutations: {
      SET_PRICE(state, payload) {
        state.price = payload
      },
      SET_REQUEST_ID(state, payload) {
        state.requestId = payload
      },
      SET_EXTERNAL_IDENTIFIER(state, payload) {
        state.externalIdentifier = payload
      },
      SET_PREORDER(state, payload) {
        state.destinationId = payload.destinationId
        state.endDate = payload.endDate
        state.nightCount = payload.nightCount
        state.pid = payload.pid
        state.startDate = payload.startDate
      },
    },
    actions: {
      setPreorder({ commit }, payload) {
        Object.keys(payload.data).forEach((key) => {
          const cookieName = `${payload.cookieName}_${key}`

          $httpCookies?.set(cookieName, payload.data[key])
        })
        commit('SET_PREORDER', {
          ...payload.data,
        })
      },
      getPreorder({ commit }, payload) {
        const keys = [
          'destinationId',
          'endDate',
          'nightCount',
          'pid',
          'startDate',
        ]
        const data = {}

        keys.forEach((key) => {
          const cookieName = `${payload.cookieName}_${key}`

          const cookie = $httpCookies?.get(cookieName)
          data[key] = cookie
        })

        if (!hasCookie(data)) {
          return new Promise(() => {
            throw 'redirect'
          })
        } else {
          commit('SET_PREORDER', {
            ...data,
          })
        }
      },
    },
  }
}
