<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { getNewUrl } = useSetRouteParams()
</script>

<template>
  <div class="locale-toggle flex items-center">
    <BaseIcon class="text-white mr-2" name="language" />

    <ul class="locale-toggle__list text-small-bold">
      <li
        v-for="locale in $i18n.locales"
        :key="locale.code"
        class="locale-toggle__list-item list-inline-item"
      >
        <a
          :href="getNewUrl(locale.code as Locale)"
          :class="[
            'locale-toggle__link text-small',
            { 'locale-toggle__link--active': $i18n.locale === locale.code },
          ]"
        >
          {{ locale.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<style>
.locale-toggle {
  margin: 0 0 0.5rem 0;
  @apply relative;
}

.locale-toggle__link {
  margin: 0 0.5rem;
  @apply text-gray-200 uppercase block;
}

.locale-toggle__link:hover {
  @apply text-white no-underline;
}

.locale-toggle__link--active {
  @apply font-medium text-primary-500;
}

.locale-toggle__link--active:hover {
  @apply text-primary-500;
}

.locale-toggle__list {
  list-style: none;
  @apply inline-block uppercase pl-0;
}
</style>
