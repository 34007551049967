<script setup lang="ts">
import { useFieldReadMode } from '~/components/BaseForm/compose/use-field-read-mode'

import type { Field } from '~/components/BaseForm/types'

const props = withDefaults(
  defineProps<{
    allData?: Record<string, unknown>
    data?: string | boolean | number | unknown[] | Record<string, unknown>
    field?: Field
    viewModeRow?: boolean
  }>(),
  {
    allData: () => ({}),
    field: () => ({}) as Field,
    data: '',
    viewModeRow: false,
  },
)

const field = toRef(props, 'field')

const {
  isCalendar,
  isInputFile,
  isSimpleCheckbox,
  isMultipleChoices,
  valueFormatted,
} = useFieldReadMode(field, props.data as string | string[] | number | boolean)
const toCalendarData = (data: unknown) =>
  typeof data === 'object'
    ? (data as { startDate: string; endDate: string })
    : undefined
</script>

<template>
  <p
    data-testid="base-field-read-mode"
    :class="[
      'base-field-read-mode-wrap',
      { 'flex-col items-start': viewModeRow },
    ]"
  >
    <span
      :class="[
        'base-field-read-mode-text',
        { 'mb-2': ['image-uploader'].includes(field.inputType) },
      ]"
    >
      <span data-testid="base-field-read-mode-label">{{
        field?.attr?.labelText || field?.attr?.label
      }}</span>
      <span
        v-if="
          !isSimpleCheckbox && (field?.attr?.labelText || field?.attr?.label)
        "
        class="mx-2"
      >
        :
      </span>
    </span>
    <slot name="value" :data-formatted="valueFormatted" :all-data="allData">
      <!-- display multiple choices -->
      <base-form-components-base-field-read-mode-multipe-choices
        v-if="isMultipleChoices"
        :values="Array.isArray(valueFormatted) ? valueFormatted : []"
      />

      <!-- simple checkbox -->
      <base-form-components-base-field-read-mode-simple-checkbox
        v-else-if="isSimpleCheckbox"
        :value="Boolean(data)"
      />

      <!-- file input -->
      <BaseFormComponentsBaseFieldReadModeInputFile
        v-else-if="isInputFile"
        :value="String(data)"
      />

      <!-- textarea or  wysiwyg -->
      <BaseFormComponentsBaseFieldReadModeTextEdit
        v-else-if="
          (field.inputType === 'textarea' || field.inputType === 'wysiwyg') &&
          typeof data === 'string'
        "
        :input-type="field.inputType"
        :value="data"
      />

      <!-- calendar -->
      <base-form-components-base-field-read-mode-calendar
        v-else-if="isCalendar"
        :value="toCalendarData(data)"
      />

      <!-- default render -->
      <span
        v-else
        data-testid="base-field-read-mode-value"
        class="base-field-read-mode-value"
      >
        {{ valueFormatted }}
      </span>
    </slot>
  </p>
</template>

<style>
.base-field-read-mode-wrap {
  --lc-form-field-read-mode-wrap-mb: 0.5rem;
  --lc-form-field-read-mode-color: #b5b5c3;
  --lc-form-field-read-mode-weight: 400;
  --lc-form-field-read-mode-value-color: #595b6b;

  @apply flex flex-wrap;
  margin-bottom: var(--lc-form-field-read-mode-wrap-mb);
}

.base-field-read-mode-value {
  @apply break-all;
  color: var(--lc-form-field-read-mode-value-color);
}
.base-field-read-mode-text {
  @apply flex items-center;
  color: var(--lc-form-field-read-mode-color);
  font-weight: var(--lc-form-field-read-mode-weight);
}
</style>
