export const defineHeader = async ({ routeName }) => {
  if (import.meta.client) {
    const { isSmallScreen } = useBreakpoint()
    const { setMenuIsOpen, setSearchIsOpen, setShowDropUp } = useHeaderMobile()
    const { setCurrentRouteTab, setActiveTabFromRoute, closeDropdown } =
      useHeaderDesktop()

    if (isSmallScreen.value) {
      const auth = useState('auth')
      if (!auth.value.authenticated) {
        return navigateTo('/login')
      }
      // Close menu / dropUp / Search
      setMenuIsOpen(false)
      setShowDropUp(false)
      setSearchIsOpen(false)
    } else {
      // Set currentRoute / ActiveTab
      setCurrentRouteTab('')
      setActiveTabFromRoute(routeName)
      // Close Dropdown
      closeDropdown()
    }
  }
}
