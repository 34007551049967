<script setup lang="ts">
import TheHeaderNavigationIconsDesktop from '~/components/TheHeader/Desktop/TheHeaderNavigationIconsDesktop.vue'
import TheHeaderDropdown from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdown.vue'
import TheHeaderDropdownInspirations from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdownInspirations.vue'
import TheHeaderDropdownAbout from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdownAbout.vue'
import TheHeaderDropdownSearch from '~/components/TheHeader/Desktop/Dropdowns/TheHeaderDropdownSearch.vue'
import TheHeaderNavigationLinksDesktop from '~/components/TheHeader/Desktop/TheHeaderNavigationLinksDesktop.vue'

const route = useRoute()
const { trackEvent } = useTracking()
const {
  headerDesktop,
  underlineLeftStyle,
  underlineRightStyle,
  closeDropdown,
  setCurrentRouteTab,
  setActiveTabFromRoute,
  setTabUnderlined,
  setTabUnderlinedFromPreviousRoute,
} = useHeaderDesktop()
const { headerNavigation, headerTheme, setTemporaryTheme } =
  useHeaderNavigation()

const props = defineProps<{
  affix: boolean
}>()

const dropdownSlide = computed(() => ({
  transform: `translate3d(-${
    headerDesktop.value.activeDropdown.index * 100
  }%, 0, 0)`,
}))
const showUnderlineLeft = computed(() =>
  Boolean(
    headerDesktop.value.underlinePosition.left.offsetLeft &&
      headerDesktop.value.underlinePosition.left.scale,
  ),
)
const showUnderlineRight = computed(() =>
  Boolean(
    headerDesktop.value.underlinePosition.right.offsetRight &&
      headerDesktop.value.underlinePosition.right.scale,
  ),
)

const areMenuItemsWhite = computed(
  () => headerTheme.value === 'transparent' && !props.affix,
)

// when a link inside a dropdown is clicked
watch(
  () => headerNavigation.value.nextRoute,
  async (val) => {
    if (val) {
      setCurrentRouteTab('')
      closeDropdown()
    }
  },
)
// set state of navbar theme + tab underline relative to dropdown
watch(
  () => headerDesktop.value.activeDropdown.name,
  (val) => {
    const theme = val || props.affix ? 'white' : ''
    setTemporaryTheme(theme)
    if (!val) {
      setTabUnderlinedFromPreviousRoute()
    }
  },
)

onMounted(async () => {
  window.addEventListener('resize', updateUnderlinePosition)
  setCurrentRouteTab('')
  setActiveTabFromRoute(route.name?.toString() || '')
})

onUnmounted(() => {
  window.removeEventListener('resize', updateUnderlinePosition)
})

const updateUnderlinePosition = () => {
  setTabUnderlined(headerDesktop.value.tabUnderlined)
}

const trackHeaderPhone = () => {
  trackEvent({
    event: 'phone_number_click',
    label: {
      section: 'header',
    },
  })
}
</script>

<template>
  <div class="relative">
    <header
      class="flex justify-between p-6 border-b z-modal header-desktop__wrapper"
    >
      <div class="flex">
        <the-header-logo />
        <the-header-navigation-links-desktop class="ml-8" />
      </div>

      <div class="flex items-center">
        <the-header-phone>
          <base-phone-number
            :color="headerTheme === 'transparent' ? 'primary-light' : 'primary'"
            @click="trackHeaderPhone"
          />
        </the-header-phone>
        <the-header-desktop-navigation-switch
          :are-menu-items-white="areMenuItemsWhite"
        />
        <the-header-navigation-icons-desktop />
      </div>
      <!-- dropdowns -->
      <the-header-dropdown>
        <div
          id="header-dropdown"
          :style="dropdownSlide"
          class="flex items-center transition duration-500 ease-out"
        >
          <TheHeaderDesktopDropdownsDestinations
            id="destinations"
            ref="destinations"
            data-tab="destinations"
            data-index="0"
            class="absolute top-0 w-full bg-white block-animation"
          />
          <the-header-dropdown-inspirations
            id="inspirations"
            ref="inspirations"
            class="absolute top-0 w-full bg-white block-animation"
            data-index="1"
            data-tab="inspirations"
          />
          <the-header-dropdown-about
            ref="about"
            class="absolute top-0 w-full bg-white block-animation"
            data-index="2"
            data-tab="about"
          />
          <the-header-dropdown-search
            ref="search"
            class="absolute top-0 w-full bg-white block-animation"
            data-index="3"
            data-tab="search"
          />
        </div>
      </the-header-dropdown>
      <WishlistMultipleSummaryWrapperDesktop />
    </header>

    <div class="absolute w-full">
      <ClientOnly>
        <div
          :class="[
            'header__underline header__underline--left',
            { show: showUnderlineLeft },
          ]"
          :style="underlineLeftStyle"
          data-testid="header__underline--left"
        />
        <div
          :class="[
            'header__underline header__underline--right',
            { show: showUnderlineRight },
          ]"
          :style="underlineRightStyle"
          data-testid="header__underline--right"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped>
.header-desktop__wrapper {
  height: var(--header-height);
  border-color: var(--header-border-color);
  background-color: var(--header-bg-color);
}

.header-desktop__phone-text {
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  color: var(--header-text-color);
}

.block-animation[data-tab='destinations'] {
  left: 0;
}
.block-animation[data-tab='inspirations'] {
  left: 100%;
}
.block-animation[data-tab='about'] {
  left: 200%;
}
.block-animation[data-tab='search'] {
  left: 300%;
}

.header__underline {
  height: 2px;
  background-color: var(--header-text-color);
  @apply absolute bottom-0;
}

.header__underline--left {
  transition:
    left 400ms ease-in-out 400ms,
    width 400ms ease-in-out 400ms,
    background-color 400ms ease-in-out 400ms,
    transform 300ms ease-in-out 230ms;
}

.header__underline--left.show {
  transition:
    left 350ms ease-in-out,
    width 350ms ease-in-out,
    background-color 350ms ease-in-out,
    transform 300ms ease-in-out 400ms;
}

.header__underline--right {
  transition:
    right 400ms ease-in-out 400ms,
    width 400ms ease-in-out 400ms,
    background-color 400ms ease-in-out 400ms,
    transform 300ms ease-in-out 230ms;
}

.header__underline--right.show {
  transition:
    right 400ms ease-in-out,
    width 400ms ease-in-out,
    background-color 400ms ease-in-out,
    transform 300ms ease-in-out 400ms;
}
</style>
