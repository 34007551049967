export default (clientHttp, apiVersion) => ({
  read(id) {
    return clientHttp.get(`${apiVersion}/owner_contracts/${id}`)
  },

  update(query, params) {
    return clientHttp.put(`${apiVersion}/owner_contracts/${query}`, {
      ...params,
    })
  },
})
