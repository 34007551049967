<script setup lang="ts">
import type { MobileStore } from '~/types/types'

const { t } = useI18n()
const localePath = useLocalePath()
const { trackEvent } = useTracking()

const config = useRuntimeConfig()

const { isMobile } = useBreakpoint()

const getFullYear = computed(() => new Date().getFullYear())
const discover = computed(() => [
  {
    id: 1,
    label: 'navbar.customHolidays',
    name: t('footer.howItWorks'),
    path: localePath('customHolidays'),
  },
  {
    id: 2,
    label: 'navbar.events',
    name: t('footer.events'),
    path: localePath('events'),
  },
  {
    id: 3,
    label: 'navbar.tailor',
    name: t('footer.tailor'),
    path: localePath('tailor'),
  },
  {
    id: 4,
    label: 'navbar.added_value',
    name: t('footer.ourAddedValue'),
    path: localePath('addedValue'),
  },
  {
    external_link: true,
    id: 5,
    name: t('footer.blog'),
    path: t('global.blog_link'),
  },
  {
    id: 6,
    label: 'navbar.travel_partners',
    name: t('footer.travelPartners'),
    path: localePath('travelPartners'),
  },
  {
    id: 7,
    label: 'footer.owners',
    name: t('footer.owners'),
    path: localePath('owners'),
  },
  {
    id: 8,
    label: 'navbar.real_estate',
    name: t('footer.realEstate'),
    path: localePath('realEstateListings'),
  },
])
const information = computed(() => [
  {
    external_link: true,
    id: 1,
    label: 'footer.recrutement',
    name: t('footer.careers'),
    nofollow: true,
    path: t('global.wttj_link'),
  },
  {
    id: 2,
    label: 'footer.privacy_policy',
    name: t('global.privacy_policy'),
    path: localePath('legal-privacyPolicy'),
    type: 'nuxt-link',
  },
  {
    id: 3,
    label: 'footer.manage_cookies',
    name: t('global.manageCookies'),
    type: 'button',
    path: 'javascript:openAxeptioCookies()',
  },
  {
    id: 4,
    label: 'footer.terms',
    name: t('global.terms_of_use'),
    path: localePath('legal-termsOfUse'),
    type: 'nuxt-link',
  },
  {
    external_link: true,
    id: 5,
    label: 'footer.faq',
    name: t('footer.faq'),
    path: t('global.faq_link'),
  },
  {
    id: 6,
    label: 'footer.contact',
    name: t('global.contactUs'),
    path: localePath('contact'),
    type: 'nuxt-link',
  },
  {
    id: 7,
    label: 'footer.sitemap',
    name: t('global.sitemap'),
    path: localePath('sitemap'),
    type: 'nuxt-link',
  },
])
const activeFeatureTouchpoint = config.public.touchpointFeature === 'true'

const trackClick = (whichDevice: MobileStore) => {
  trackEvent({
    event: 'touchpoint_store',
    device: whichDevice,
    source: 'footer',
  })
}
</script>

<template>
  <footer class="footer">
    <base-container>
      <base-row>
        <base-col class="w-full" sm="12" md="5">
          <ul class="footer__switches">
            <li>
              <base-switch-locale />
            </li>
            <li class="w-1/2">
              <base-switch-currency class="footer__switches__currency" />
            </li>
          </ul>

          <TheFooterSocialNetwork />
          <div
            v-if="activeFeatureTouchpoint"
            class="mb-4 flex flex-row md:flex-col mx-2"
          >
            <NuxtLink :to="config.public.ownerAppIOS" :target="'_blank'">
              <img
                alt="logo-apple-store"
                src="~/assets/svg/AppStore.svg"
                :width="$device.isMobile ? '136' : '90'"
                :height="$device.isMobile ? '40' : '27'"
                @click="trackClick('app-store')"
              />
            </NuxtLink>
            <NuxtLink :to="config.public.ownerAppAndroid" :target="'_blank'">
              <img
                alt="logo-play-store"
                src="~/assets/svg/Play.svg"
                :width="$device.isMobile ? '136' : '90'"
                :height="$device.isMobile ? '40' : '27'"
                :class="$device.isMobile ? 'ml-10' : 'mt-2'"
                @click="trackClick('google-play')"
              />
            </NuxtLink>
          </div>
        </base-col>

        <div class="flex flex-col md:flex-row justify-items-stretch">
          <the-footer-list :title="$t('footer.discover')" :list="discover" />
          <the-footer-list
            :title="$t('footer.get_informed')"
            :list="information"
          />
        </div>

        <base-col sm="12" md="9">
          <TheFooterSubscribeToNewsletter />
          <div class="flex justify-center gap-2 mb-2 md:justify-normal">
            <img
              :height="isMobile ? 68 : 60"
              :width="isMobile ? 68 : 60"
              alt="Condénast Traveler readers’ choice awards 2023"
              loading="lazy"
              src="~/assets/images/condenast/condenast_2023.svg"
            />
            <img
              :height="isMobile ? 68 : 60"
              :width="isMobile ? 68 : 60"
              alt="Condénast Traveler readers’ choice awards 2022"
              loading="lazy"
              src="~/assets/images/condenast/condenast_2022.svg"
            />
            <img
              :height="isMobile ? 68 : 60"
              :width="isMobile ? 68 : 60"
              alt="Condénast Traveler readers’ choice awards 2021"
              loading="lazy"
              src="~/assets/images/condenast/condenast_2021.svg"
            />

            <img
              :height="isMobile ? 68 : 60"
              :width="isMobile ? 68 : 60"
              alt="Condénast Traveler readers’ choice awards 2020"
              loading="lazy"
              src="~/assets/images/condenast/condenast_2020.svg"
            />
          </div>
          <div class="flex justify-center mb-4 md:justify-normal">
            <img
              :height="isMobile ? 66 : 47"
              :width="isMobile ? 156 : 109"
              alt="Serandipians"
              loading="lazy"
              src="~/assets/images/serandipians/serandipians_white.svg"
            />
          </div>
          <p class="text-white text-small text-center md:text-left">
            {{ getFullYear }} {{ $t('footer.all_right_reserved') }}
          </p>
        </base-col>
      </base-row>
    </base-container>
  </footer>
</template>

<style>
.footer {
  @apply bg-gray-700 py-16 px-0;
}

.footer__switches {
  @apply pl-0;
  list-style: none;
  margin: 0 0 3rem 0;
}

@screen sm {
  .footer__switches {
    margin: 0 0 1rem 0;
  }
}

.footer__switches__currency .switch-currency__icn {
  @apply text-white;
}

.footer__switches__currency .btn .switch-currency__currency {
  @apply text-primary-500;
}

.footer__switches__currency .btn .switch-currency__currency:hover {
  @apply border-primary-500;
}
</style>
