export default defineNuxtRouteMiddleware(async () => {
  const { $api, $storeGlobal, $httpCookies, $lcRepositories } = useNuxtApp()

  const uid = $httpCookies.get('uid')
  const client = $httpCookies.get('client')
  const accessToken = $httpCookies.get('access-token')

  if (accessToken && client && uid) {
    await $api.setHeader({
      'access-token': accessToken,
      client,
      uid,
    })

    $lcRepositories.headers.setHeaders({
      accessToken,
      client,
      uid,
    })

    try {
      const { data: user } = await useAsyncData('validateToken', () =>
        $api.v1.auth.validateToken(),
      )

      if (user.value?.data && user.value?.data?.incompleteSignup === false) {
        useState('auth').value = {
          user: { ...user.value.data },
          authenticated: true,
        }

        $storeGlobal.commit('auth/SET_USER', user.value.data)
        $storeGlobal.commit('auth/SET_LOGGED_IN', true)

        // Set User
        if (user.value.data.algoliaAdminSearchKey) {
          $httpCookies.set(
            'search_admin_token',
            user.value.data.algoliaAdminSearchKey,
          )
        }
        if (user.value.data.algoliaB2b2cSearchKey) {
          $httpCookies.set(
            'search_b2b2c_token',
            user.value.data.algoliaB2b2cSearchKey,
          )
        }
      }
    } catch {
      useState('auth').value = {
        user: {},
        authenticated: false,
      }

      $storeGlobal.commit('auth/SET_USER', {})
      $storeGlobal.commit('auth/SET_LOGGED_IN', false)
    }
  } else {
    useState('auth').value = {
      user: {},
      authenticated: false,
    }
  }
})
