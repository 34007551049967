<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'
// @ts-expect-error Module '"~/node_modules/vue-calendar-3/types/main"' has no exported member 'Calendar'.
import { Calendar } from 'vue-calendar-3'
import type {
  Booking,
  BookingColor,
  CheckInCheckOutHalfDay,
  Day,
  Period,
} from 'vue-calendar-3'
import type { Placeholder, Rules } from '../types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    bookingColor?: BookingColor
    bookingDates?: Booking[]
    calendarEndDate?: string | Date
    calendarStartDate?: string | Date
    disabled?: boolean
    disabledDaysBeforeDayDate?: boolean
    endDate?: string | Date | null
    formatDate?: string
    isAffixed?: boolean
    label?: string
    labelHtml?: string
    name: string
    periodDates?: Period[]
    placeholder?: Placeholder
    rules?: Rules
    startDate?: string | Date | null
    wrapperClass?: string
  }>(),
  {
    bookingColor: () => ({}),
    bookingDates: () => [],
    calendarEndDate: () => new Date(new Date().getFullYear() + 3, 0, 1),
    calendarStartDate: () => new Date(new Date().getFullYear() - 3, 0, 1),
    disabled: false,
    disabledDaysBeforeDayDate: false,
    endDate: null,
    formatDate: 'DD-MM-YYYY',
    isAffixed: false,
    label: '',
    labelHtml: '',
    periodDates: () => [],
    placeholder: () => ({}) as Placeholder,
    rules: '',
    startDate: null,
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  'clear-dates': []
  'select-booking-date': [
    {
      day: Day
      booking: Booking
      checkIncheckOutHalfDay: CheckInCheckOutHalfDay
      e: Event
    },
  ]
  'update:end-date': [Date]
  'update:start-date': [Date]
}>()
const baseFormCalendar: {
  value: Calendar | null
} = ref(null)
const rules = toRef(props, 'rules')
const startDate = toRef(props, 'startDate')
const endDate = toRef(props, 'endDate')

const { locale } = useI18n()
const {
  value: inputValue,
  handleChange,
  errors,
} = useField(props.name, rules, {
  initialValue: { startDate: startDate.value, endDate: endDate.value },
  validateOnValueUpdate: false,
})

watch([startDate, endDate], () => {
  inputValue.value.startDate = startDate.value
  inputValue.value.endDate = endDate.value

  if (startDate.value && endDate.value) {
    handleChange({ startDate: startDate.value, endDate: endDate.value })
  }
})

const selectBookingDate = (
  day: Day,
  booking: Booking,
  checkIncheckOutHalfDay: CheckInCheckOutHalfDay,
  e: Event,
) => {
  emits('select-booking-date', {
    day,
    booking,
    checkIncheckOutHalfDay,
    e,
  })
}

const hasError = computed(() => Boolean(errors.value.length))
const showYear = ref(false)

const handleClearDates = () => {
  emits('clear-dates')
}
const handleCheckIn = (date: Date, updateDate = true) => {
  if (updateDate) {
    emits('update:start-date', date)
  }
}
const handleCheckOut = (date: Date, updateDate = true) => {
  if (updateDate) {
    emits('update:end-date', date)
  }
}

// Expose methods and data
const activeIndex: ComputedRef<number> = computed(
  () => baseFormCalendar.value?.activeIndex,
)
const showCalendar: ComputedRef<boolean> = computed(
  () => baseFormCalendar.value?.showCalendar,
)
const clearDatesPicker = () => {
  baseFormCalendar.value?.clearDates()
}
const closeCalendar = () => {
  baseFormCalendar.value?.closeCalendar()
}
const toggleCalendar = () => {
  baseFormCalendar.value?.toggleCalendar()
}
const openCalendar = () => {
  baseFormCalendar.value?.openCalendar()
}
const clickOutsideCloseCalendar = () => {
  if (!props.isAffixed) closeCalendar()
}

const weekDays = {
  fr: ['Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.'],
  en: ['Su.', 'Mo.', 'Tu.', 'We.', 'Th.', 'Fr.', 'Sa.'],
}

defineExpose({
  activeIndex,
  clearDatesPicker,
  closeCalendar,
  openCalendar,
  showCalendar,
  toggleCalendar,
})
defineSlots<{
  header(): any
  label(): any
  'lc-calendar-footer'(p: {
    clearDates: () => void
    closeDatePicker: () => void
  }): any
  'lc-calendar-header-mobile'(p: {
    clearDates: () => void
    closeDatePicker: () => void
  }): any
}>
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <Calendar
      :id="name"
      ref="baseFormCalendar"
      v-click-outside="clickOutsideCloseCalendar"
      :booking-color="bookingColor"
      :booking-dates="bookingDates"
      :check-in="inputValue.startDate"
      :check-out="inputValue.endDate"
      :class="[{ 'base-calendar--hasError': hasError }]"
      :data-cy="name"
      :disabled-days-before-day-date="disabledDaysBeforeDayDate"
      :disabled="disabled"
      :end-date="calendarEndDate"
      :format-date="formatDate"
      :is-affixed="isAffixed"
      :locale="locale"
      :period-dates="periodDates"
      :placeholder="placeholder"
      :show-input-calendar="!showYear"
      :show-year="showYear"
      :start-date="calendarStartDate"
      v-bind="$attrs"
      @update:check-in="handleCheckIn"
      @update:check-out="handleCheckOut"
      @clear-dates="handleClearDates"
      @select-booking-date="selectBookingDate"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #calendar-header-mobile="{ clearDates, closeDatePicker }">
        <slot
          name="lc-calendar-header-mobile"
          :clear-dates="clearDates"
          :close-date-picker="closeDatePicker"
        />

        <div class="font-bold text-center mb-4">
          {{ $t('calendar.stayDuration') }}
        </div>
        <div class="grid grid-cols-7 border-b border-gray-200 pb-1">
          <div
            v-for="day in weekDays[locale as 'fr' | 'en']"
            :key="`day-${day}`"
            class="text-md text-gray-400 text-center"
          >
            {{ day }}
          </div>
        </div>
      </template>
      <template #calendar-footer="{ clearDates, closeDatePicker }">
        <slot
          name="lc-calendar-footer"
          :clear-dates="clearDates"
          :close-date-picker="closeDatePicker"
        />
      </template>
    </Calendar>

    <ErrorMessage as="div" :name="name" class="base-form--error" />
  </div>
</template>

<style src="vue-calendar-3/dist/style.css"></style>

<style>
.base-calendar--hasError {
  @apply border border-error;
}
.calendar_wrapper {
  @apply z-10;
}

.vue-calendar .calendar_day--in-period .calendar_day--day-number {
  color: #202020;
}
@supports (text-wrap: nowrap) {
  .vue-calendar .calendar_input-text--checkIn {
    text-wrap: nowrap;
  }
}
@supports not (text-wrap: nowrap) {
  .vue-calendar .calendar_input-text--checkIn {
    min-width: 6rem;
  }
}
</style>
