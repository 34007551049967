<script setup lang="ts">
import { ErrorMessage, useField } from 'vee-validate'

const { getApiURL } = useURL()

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    hasBorder?: boolean
    infoText?: string
    inputHtml?: string
    label?: string
    labelHtml?: string
    modelValue?: string
    name: string
    placeholder?: string
    rules?: string
    showCurrentFile?: boolean
    wrapperClass?: string
  }>(),
  {
    disabled: false,
    hasBorder: true,
    infoText: '',
    inputHtml: '',
    label: '',
    labelHtml: '',
    modelValue: '',
    placeholder: '',
    rules: '',
    showCurrentFile: false,
    wrapperClass: 'w-full mb-4',
  },
)

const emits = defineEmits<{
  'update:model-value': [string]
}>()
const baseUrl = `${getApiURL({})}/`
const { t } = useI18n()
// if file exists, remove required rules
const rules = ref(props.rules)
if (props.modelValue) {
  rules.value = ''
}

const initialModelValue = props.modelValue
const baseFileInput = ref(null)

const {
  value: inputValue,
  handleChange,
  errors,
} = useField<string>(props.name, rules)

const isError = computed(() => Boolean(errors.value.length))

function onChange(): void {
  const input = baseFileInput.value as unknown as { value: unknown }
  handleChange(input?.value)

  emits('update:model-value', inputValue.value)
}

const filePlaceholder = computed(() => {
  const valuePlaceholder = inputValue.value
    ? inputValue.value.replace(/\\/g, '/').split('/').pop()
    : null

  return valuePlaceholder || t('global.placeholders.chooseFile')
})
const filename = computed(() => {
  return initialModelValue ? `${baseUrl}${initialModelValue.substring(1)}` : ''
})
const computedClass = computed((): string[] => {
  const computedClass = ['base-file-input']

  if (!props.hasBorder) {
    computedClass.push('base-file-input--noBorder')
  }
  if (props.disabled) {
    computedClass.push('base-file-input--disabled')
  }
  if (isError.value && props.hasBorder) {
    computedClass.push('base-file-input--hasError-hasBorder')
  }
  if (!isError.value && props.hasBorder) {
    computedClass.push('base-file-input--hasntError-hasBorder')
  }

  return computedClass
})
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label">
      <label v-if="label" :for="name" class="flex">
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
      </label>
    </slot>

    <input
      :id="name"
      ref="baseFileInput"
      :name="name"
      :placeholder="placeholder"
      :value="inputValue"
      class="base-file-input-hidden"
      type="file"
      v-bind="$attrs"
      @change="onChange"
    />

    <label :for="name" :class="computedClass">
      <span class="truncate">{{ filePlaceholder }}</span>
      <BaseIcon class="text-black" name="attachment" />
    </label>

    <BaseFormComponentsBaseStringToHtml
      v-if="inputHtml"
      :str-html="inputHtml"
    />

    <BaseFormComponentsBaseFieldInfo v-if="infoText" :text="$t(infoText)" />

    <error-message :name="name" as="span" class="base-form--error" />

    <p v-if="initialModelValue && showCurrentFile" class="mt-7">
      {{ $t('global.currentFile') }}:
      <base-button :href="filename" variant="link" target="_blank">
        {{ $t('global.download') }}
      </base-button>
    </p>
  </div>
</template>

<style>
.base-file-input-hidden {
  @apply absolute opacity-0 overflow-hidden w-px h-px;
  z-index: -1;
}
.base-file-input {
  height: 51px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  @apply flex justify-between	cursor-pointer items-center bg-clip-padding w-full text-gray-500 font-normal text-base leading-normal py-1.5 px-4 bg-white focus:text-gray-500 focus:bg-white focus:border-primary-100 focus:shadow-focus focus:outline-none border rounded-sm;
}
.base-file-input--noBorder {
  @apply border-none;
}
.base-file-input--disabled {
  @apply bg-gray-200 pointer-events-none text-gray-500;
}
.base-file-input--hasError-hasBorder {
  @apply border-error;
}
.base-file-input--hasntError-hasBorder {
  @apply border-gray-200;
}
.base-file-input[disabled='disabled'] {
  -webkit-text-fill-color: #aaaaaa;
}
</style>
