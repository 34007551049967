<script setup lang="ts">
import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import type { HouseSlug, Locale } from 'lc-services/types'

defineEmits<{
  'close-menu-mobile': []
}>()

const { locale } = useI18n()
const { headerNavigation } = useHeaderNavigation()

const inspirationsLinks = computed(
  () => headerNavigation.value.inspirationsLinks,
)
const getPathDestination = (destinationNames: HouseSlug) =>
  getDestinationSlug(destinationNames)[locale.value as Locale].path
</script>

<template>
  <div class="px-4 py-8 tablet:px-12 tablet:py-10">
    <div class="text-center border-b border-gray-200 mb-10">
      <p class="mb-8">
        {{ $t('headerInspirations.joinMagazine') }}
      </p>
      <base-button
        :href="$t('global.blog_link')"
        class="mb-10"
        color="secondary"
        target="_blank"
      >
        {{ $t('headerInspirations.exploreTheMagazine') }}
      </base-button>
    </div>

    <div
      class="text-2xl tablet:text-4xl leading-none font-monospace mb-6 tablet:mb-8"
    >
      {{ $t('headerInspirations.collections') }}
    </div>

    <ul v-if="inspirationsLinks.length" class="tablet:flex tablet:flex-wrap">
      <li
        v-for="(link, index) in inspirationsLinks"
        :key="index"
        class="flex items-center mb-4 tablet:w-1/2"
      >
        <div class="w-[60px] h-[60px] shrink-0">
          <NuxtLink
            :to="getPathDestination(link.href)"
            class="flex-none rounded-md block"
            @click="$emit('close-menu-mobile')"
          >
            <BaseNuxtImg
              :alt="$i18n.locale === 'fr' ? link.titleFr : link.titleEn"
              :src="link.img"
              height="60"
              loading="lazy"
              width="60"
            />
          </NuxtLink>
        </div>

        <base-button
          :to="getPathDestination(link.href)"
          class="flex-grow screen-about-link"
          color="secondary-black"
          data-testid="screen-about__link"
          font-weight="font-normal"
          variant="link"
          @click="$emit('close-menu-mobile')"
        >
          {{ $i18n.locale === 'fr' ? link.titleFr : link.titleEn }}
        </base-button>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.screen-about-link {
  @apply pl-7;
}
</style>
